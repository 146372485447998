import React, { Component } from 'react';
import './StreamComponent.css';
import OvVideoComponent from './OvVideo';
import { OpenVidu } from 'openvidu-browser';

import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { isMobile, browserName, isIE, isMobileOnly, isSafari } from "react-device-detect";

const recordSVG = require('./record.svg');
const recordHoverSVG = require('./record-hover.svg');

const stopGIF = require('./stop-record.gif');

const splitSVG = require('./split-screen.svg');
const split1SVG = require('./split-screen-1.svg');
const split2SVG = require('./split-screen-2.svg');
const split3SVG = require('./split-screen-3.svg');
const split4SVG = require('./split-screen-4.svg');

const StyledTooltip = withStyles({
  popper: {
    zIndex : 9999999999,

  },
  tooltip: {
    backgroundColor : '#2B2B2B',
    fontSize : '12px',
    fontFamily : 'inherit',
    borderRadius : 0,
  },
})(Tooltip);


export default class StreamComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          infoDevices : undefined,
          showViews : false,
          nickname: this.props.user.getNickname(),
          showForm: false,
          mutedSound: false,
          isFormValid: true,
          recording : false,
          view : "2",
          screenOrientation : undefined,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePressKey = this.handlePressKey.bind(this);
        this.toggleNicknameForm = this.toggleNicknameForm.bind(this);
        this.toggleSound = this.toggleSound.bind(this);


        this.testDbClick = this.testDbClick.bind(this);
        this.handleNameAndPoint = this.handleNameAndPoint.bind(this);
        this.handleRecorder = this.handleRecorder.bind(this);
        this.handleStopRecorder = this.handleStopRecorder.bind(this);
        this.handleVolume = this.handleVolume.bind(this);
        this.handleSecondCam = this.handleSecondCam.bind(this);
        this.handleDoNothing = this.handleDoNothing.bind(this);

        this.handleChangeView = this.handleChangeView.bind(this);

        this.OV = new OpenVidu();
        this.OV.enableProdMode();
        this.recorder = null;


    }

    componentDidMount() {
      this.orientationListener();
      window.addEventListener("orientationchange", this.orientationListener);

      if (this.props.user.isLocal()) {
        /*this.props.user.getStreamManager().on('streamAudioVolumeChange', (event) => {
          var value = event.value.newValue;
          console.log(value);
        });*/
        if (this.state.infosDevices !== this.props.infosDevices) {
          this.setState({
            infosDevices : this.props.infosDevices
          });
        }

        if (this.props.screenShare != true) {
          this.recorder = this.OV.initLocalRecorder(this.props.actStream, this.props.user.getNickname());
        }
      } else {
        this.props.user.getStreamManager().on('streamAudioVolumeChange', (event) => {
          var value = event.value.newValue;
          if (value <= -100) {
            value = -100 + 100;
          } else if (value >= 0) {
            value = 0 + 100;
          } else {
            value = value + 100;
          }
          value = value*2; //correction
          if (this.refs.iconVolume) {
            this.refs.iconVolume.style.backgroundImage="-webkit-linear-gradient(top, #FFFFFF "+(100-Math.round(value))+"%, #0E93D2)";
          }
        });
      }
    }

    componentWillUnmount() {
      //this.props.user = null;
      if (this.props.user.getStreamManager()) {
        this.props.user.getStreamManager().off('streamAudioVolumeChange');
      }

      window.removeEventListener("orientationchange", this.orientationListener);
    }

    componentDidUpdate() {
      if (this.props.user.isLocal()) {
        if (this.state.infosDevices !== this.props.infosDevices) {
          this.setState({
            infosDevices : this.props.infosDevices
          });
        }
      } else {
        this.props.user.getStreamManager()
      }
    }

    orientationListener = () => {
      if ((window.orientation == 0) || (window.orientation) == 180) {
        this.setState({ screenOrientation: 'portait' })
      } else {
        this.setState({ screenOrientation: 'landscape' })
      }
    }


    handleChange(event) {
        this.setState({ nickname: event.target.value });
        event.preventDefault();
    }

    toggleNicknameForm() {
        if (this.props.user.isLocal()) {
            this.setState({ showForm: !this.state.showForm });
        }
    }

    toggleSound() {
        this.setState({ mutedSound: !this.state.mutedSound });
    }

    testDbClick(event) {
      if (this.props.embededMode != "true") {
        if (!isMobileOnly) {
        	var target = event.target.parentNode.parentNode.parentNode;
        	if (target.classList.contains("OV_big")) {
        		target.classList.remove("OV_big");
    	    	this.props.hgl_layout.updateLayout();
        	} else {
      			var elems = this.props.hgl_layout.layoutContainer.childNodes;
      			for (var i=0; i<elems.length; i++) {
      				elems[i].classList.remove("OV_big");
      			}
    	    	target.classList.add("OV_big");
    	    	this.props.hgl_layout.updateLayout();
    	    }
        }
      }
    }

    handlePressKey(event) {
        if (event.key === 'Enter') {
            //console.log(this.state.nickname);
            if (this.state.nickname.length >= 3 && this.state.nickname.length <= 20) {
                this.props.handleNickname(this.state.nickname);
                this.toggleNicknameForm();
                this.setState({ isFormValid: true });
            } else {
                this.setState({ isFormValid: false });
            }
        }
    }

    handleNameAndPoint(event) {
      event.stopPropagation();
      if (!isMobileOnly) {
        var target = event.target.parentNode.parentNode.parentNode.parentNode;
        if (target.classList.contains("bounds")) {
          target = event.target.parentNode.parentNode.parentNode;
        }
        if (target.classList.contains("OV_big")) {

      		target.classList.remove("OV_big");

  	    	this.props.hgl_layout.updateLayout();

      	} else {

    			var elems = this.props.hgl_layout.layoutContainer.childNodes;
    			for (var i=0; i<elems.length; i++) {
    				elems[i].classList.remove("OV_big");
    			}

  	    	target.classList.add("OV_big");

  	    	this.props.hgl_layout.updateLayout();

  	    }
      }
    }

    handleRecorder(event) {
      event.stopPropagation();
      var _this = this;


      /*console.log(this.props.actStream.getVideoTracks()[0]);
      console.log(this.props.actStream.getAudioTracks()[0]);*/

      this.recorder.record().then(function() {
        _this.setState({ recording : true });
        //console.log("recording start");
      });


    }

    handleStopRecorder(event) {
      event.stopPropagation();
      var _this = this;

      this.recorder.stop().then(function() {
        _this.setState({ recording : false });
        _this.props.askRecordFile(_this.recorder);
        /*_this.recorder.download();
        _this.recorder.clean();*/
        //console.log("recording stop");
      });
    }

    handleVolume(event) {
      //TODO -> volume à 50%
      event.stopPropagation();
      this.toggleSound();
    }

    handleSecondCam(event) {
      event.stopPropagation();
      this.setState({ showViews: !this.state.showViews });
    }

    handleDoNothing(event) {
      event.stopPropagation();
    }

    handleChangeView(event) {
      event.stopPropagation();

      var streamWidth = 640;
      var streamHeight = 360;

      var videosArray = this.props.streamMixer.getVideos();

      switch (event.target.id) {
        case '2':
          videosArray[0].stream.fullcanvas = true;
          videosArray[0].stream.width = streamWidth;
          videosArray[0].stream.height = streamHeight;
          videosArray[0].stream.top = 0;
          videosArray[0].stream.left = 0;

          videosArray[1].stream.fullcanvas = false;
          videosArray[1].stream.width = parseInt((20 / 100) * streamWidth);
          videosArray[1].stream.height = parseInt((20 / 100) * streamHeight);
          videosArray[1].stream.top = streamHeight - videosArray[1].stream.height;
          videosArray[1].stream.left = streamWidth - videosArray[1].stream.width;

          //this.streamMixer.updateVideosArray(videosArray);


          break;
        case '1':

          videosArray[0].stream.fullcanvas = false;
          videosArray[0].stream.width = parseInt((20 / 100) * streamWidth);
          videosArray[0].stream.height = parseInt((20 / 100) * streamHeight);
          videosArray[0].stream.top = streamHeight - videosArray[0].stream.height;
          videosArray[0].stream.left = streamWidth - videosArray[0].stream.width;

          videosArray[1].stream.fullcanvas = true;
          videosArray[1].stream.width = streamWidth;
          videosArray[1].stream.height = streamHeight;
          videosArray[1].stream.top = 0;
          videosArray[1].stream.left = 0;

          //this.streamMixer.updateVideosArray(videosArray);

          break;
          case '0':

            videosArray[1].stream.fullcanvas = false;
            videosArray[1].stream.width = streamWidth/2;
            videosArray[1].stream.height = streamHeight/2;
            videosArray[1].stream.top = (streamHeight - (streamHeight/2) ) /2;
            videosArray[1].stream.left = 0;

            videosArray[0].stream.fullcanvas = false;
            videosArray[0].stream.width = streamWidth/2;
            videosArray[0].stream.height = streamHeight/2;
            videosArray[0].stream.top = (streamHeight - (streamHeight/2) ) /2;
            videosArray[0].stream.left = streamWidth/2;


            break;
          case '4':
            videosArray[0].stream.fullcanvas = true;
            videosArray[0].stream.width = streamWidth;
            videosArray[0].stream.height = streamHeight;
            videosArray[0].stream.top = 0;
            videosArray[0].stream.left = 0;

            videosArray[1].stream.fullcanvas = false;
            videosArray[1].stream.width = 0;
            videosArray[1].stream.height = 0;
            videosArray[1].stream.top = 0;
            videosArray[1].stream.left = 0;

            break;
          case '3':
            videosArray[1].stream.fullcanvas = true;
            videosArray[1].stream.width = streamWidth;
            videosArray[1].stream.height = streamHeight;
            videosArray[1].stream.top = 0;
            videosArray[1].stream.left = 0;

            videosArray[0].stream.fullcanvas = false;
            videosArray[0].stream.width = 0;
            videosArray[0].stream.height = 0;
            videosArray[0].stream.top = 0;
            videosArray[0].stream.left = 0;

            break;

      }
      this.setState({ view: event.target.id, showViews:false });
    }

    render() {
        var mainCamIcon = split2SVG;
        if (this.state.view == "0") {
          mainCamIcon = splitSVG;
        } else if (this.state.view == "1") {
          mainCamIcon = split1SVG;
        } else if (this.state.view == "2") {
          mainCamIcon = split2SVG;
        } else if (this.state.view == "3") {
          mainCamIcon = split3SVG;
        } else if (this.state.view == "4") {
          mainCamIcon = split4SVG;
        }

        var nicknameClassName = "nickname";
        if (this.props.user.isLocal() == true) {
          if ((isSafari == true) || (isIE == true)) {
            nicknameClassName = "nickname-center";
          }
        }

        var nicknameTextClassName = "nicknameText";
        if (isMobileOnly) {
          if (this.state.screenOrientation == 'landscape') {
            nicknameTextClassName = "nicknameText-paysage";
            if ((isSafari == true) || (isIE == true)) {
              nicknameClassName = "nickname-center nicknamepaysage-center";
            } else {
              nicknameClassName = "nickname nicknamepaysage";
            }
          } else {
            nicknameTextClassName = "nicknameText-portrait";
          }
        }
        return (

            <div className="OT_widget-container" onClick={this.testDbClick}>
                {this.props.user.isLocal() && this.props.screenShare != true && this.state.infosDevices !== undefined && this.state.infosDevices.selectVideo2.value !== "none" ? (
                  <div>
                    <div className="secondCam" onClick={this.handleSecondCam}>
                      <img
                          src={mainCamIcon}
                          className="secondCamIconMain"
                          onClick={this.handleSecondCam}
                      />
                    </div>
                      {this.state.showViews ? (
                        <div className="secondCamHover" onClick={this.handleDoNothing}>
                          <div className="secondCamHoverMenu">
                            <img
                                src={split4SVG}
                                onClick={this.handleChangeView}
                                id="4"
                            />
                            <img
                                src={split3SVG}
                                onClick={this.handleChangeView}
                                id="3"
                            />
                            <img
                                src={split2SVG}
                                onClick={this.handleChangeView}
                                id="2"
                            />
                            <img
                                src={split1SVG}
                                onClick={this.handleChangeView}
                                id="1"
                            />
                            <img
                                src={splitSVG}
                                onClick={this.handleChangeView}
                                id="0"
                            />
                          </div>
                        </div>
                      ) : null }
                  </div>
                ) : null }



                <div className={nicknameClassName}>
                  {this.props.user.isLocal() && this.props.screenShare != true && isSafari == false && isIE == false ? (
                    <div>
                      {this.state.recording == false ?(
                        <StyledTooltip title="Enregistrer" placement="top">
                          <img
                              onClick={this.handleRecorder}
                              className="recorder"
                              src={recordSVG}
                              onMouseOver={e => (e.currentTarget.src = recordHoverSVG)}
                              onMouseOut={e => (e.currentTarget.src = recordSVG)}
                          />
                        </StyledTooltip>
                      ): (
                        <StyledTooltip title="Stopper l'enregistrement" placement="top">
                          <img
                              onClick={this.handleStopRecorder}
                              className="stoprecorder"
                              src={stopGIF}
                          />
                        </StyledTooltip>
                      )}
                    </div>
                  ) : null }
                  {this.props.user.isLocal() == false ? (
                    <div>
                      {this.state.mutedSound ? (
                        <div className="volume" onClick={this.handleVolume}>
                          <span className="icon-volume-mute"></span>
                        </div>
                      ) : (
                        <div className="volume" onClick={this.handleVolume}>
                          <span ref="iconVolume" className="icon-volume"></span>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {isMobileOnly == false && this.props.embededMode != "true" ? (
                    <StyledTooltip title="Epingler" placement="top">
                      <div className="bigPoint" onClick={this.handleNameAndPoint}>
                        <span style={{alignSelf : 'center'}} className="icon-Oval-1"></span>
                      </div>
                    </StyledTooltip>
                  ) : null}

                  <div className={nicknameTextClassName} onClick={this.handleNameAndPoint}>
                    <span id="nickname" className="nicknameSpan">{this.props.user.getNickname()}</span>
                  </div>
                </div>

                {this.props.user !== undefined && this.props.user.getStreamManager() !== undefined ? (
                    <div className="streamComponent">
                        <OvVideoComponent user={this.props.user} mutedSound={this.state.mutedSound} />
                    </div>
                ) : null}
            </div>
        );
    }
}
