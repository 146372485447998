import React, { Component } from 'react';
import Select, { components } from 'react-select'

import './RoomConfig.css';

import { OpenVidu } from 'openvidu-browser';

import {
  Container, Row, Col, Form, Input, Button
} from 'reactstrap';

import LoadingImg from '../../images/loading-black.gif';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import {
  isBrowser,
  isMobile,
  isMobileOnly,
  browserName,
  isSafari,
  isMobileSafari,
  isIOS,
} from "react-device-detect";

import MultiStreamsMixer from 'multistreamsmixer_hgl';
import { ov_api } from '../../api/apis.js'

const StyledTooltip = withStyles({
  popper: {
    zIndex : 9999999999,

  },
  tooltip: {
    backgroundColor : '#2B2B2B',
    fontSize : '12px',
    fontFamily : 'inherit',
    borderRadius : 0,
  },
})(Tooltip);

if (typeof window !== 'undefined') {
  var AudioContext = window.AudioContext // Default
  || window.webkitAudioContext // Safari and old versions of Chrome
  || false;
}

var audioContext = null;
var analyser = null;
var microphone = null;
var javascriptNode = null;

const xCloseIcon = require('../../images/x-close.svg');
const flecheSelectSVG = require('./flecheSelect.svg');

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '23px',
    overflow:'hidden',
    cursor:'pointer',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  noOptionsMessage : (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '20px',
    overflow:'hidden',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingTop : '2px',
    paddingBottom : '2px',
    paddingLeft : '20px',
    paddingRight : '8px',
    marginRight: '10px',
  }),
  indicatorSeparator : () => ({
    visibility : 'hidden',
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    width:'40px',
  }),
  control : () => ({
    border:'1px solid #E8E8E8',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    outline: '0 !important',
    height:'60px',
    borderRadius: '7px'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  container : () => ({
    height:'60px',
    cursor:'pointer',
  }),
  menu : () => ({
    position:'relative',
    top : '-60px',
    /*width : '330px',*/
    borderRadius : '7px',
    margin : '0',
    zIndex : '1',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.3)',
  }),
  menuList : () => ({
    padding : '0',
    backgroundColor: 'white',
    maxHeight : '200px',
    overflowY: 'auto',
    borderRadius : '7px'

  }),
}

const customStylesMobile = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '23px',
    overflow:'hidden',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  noOptionsMessage : (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '20px',
    overflow:'hidden',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingTop : '2px',
    paddingBottom : '2px',
    paddingLeft : '20px',
    paddingRight : '8px',
    marginRight: '10px',
  }),
  indicatorSeparator : () => ({
    visibility : 'hidden',
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    width:'40px',
  }),
  control : () => ({
    border:'1px solid #E8E8E8',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    outline: '0 !important',
    height:'60px',
    borderRadius: '7px'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  container : () => ({
    height:'60px',
  }),
  menu : () => ({
    position:'relative',
    top : '0px',
    width : '100%',
    borderRadius : '0',
    margin : '0',
    zIndex : '1',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.3)',
    transform : 'translateY(-100%)',
    borderRadius : '7px',
  }),
  menuList : () => ({
    padding : '0',
    backgroundColor: 'white',
    maxHeight : '200px',
    overflowY: 'auto',
    borderRadius : '7px',
  }),
}

const customStylesMobile2 = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '23px',
    overflow:'hidden',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  noOptionsMessage : (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F3F3' : 'white',
    height:'60px',
    lineHeight :'44px',
    color:'#8F8F8F',
    fontSize:'14px',
    paddingLeft : '20px',
    overflow:'hidden',
    '&:active': {
      backgroundColor : "#0E93D2",
      color : 'white'
    }
  }),
  valueContainer : (provided, state) => ({
    ...provided,
    paddingTop : '2px',
    paddingBottom : '2px',
    paddingLeft : '20px',
    paddingRight : '8px',
    marginRight: '10px',
  }),
  indicatorSeparator : () => ({
    visibility : 'hidden',
  }),
  indicatorsContainer : (provided, state) => ({
    ...provided,
    width:'40px',
  }),
  control : () => ({
    border:'1px solid #E8E8E8',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    outline: '0 !important',
    height:'60px',
    borderRadius: '7px'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color:'#8F8F8F',
    fontSize:'14px',
  }),
  container : () => ({
    height:'60px',
  }),
  menu : () => ({
    position:'relative',
    top : '-60px',
    width : '100%',
    borderRadius : '0',
    margin : '0',
    zIndex : '1',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.3)',
    borderRadius : '7px',
  }),
  menuList : () => ({
    padding : '0',
    backgroundColor: 'white',
    maxHeight : '200px',
    overflowY: 'auto',
    borderRadius : '7px',
  }),
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={flecheSelectSVG}/>
    </components.DropdownIndicator>
  );
};


export default class RoomConfig extends Component {
    constructor(props) {
        super(props);

        this.askToJoin = this.askToJoin.bind(this);
        this.fetchStudentSessions = this.fetchStudentSessions.bind(this);
        this.joinOnlyRecord = this.joinOnlyRecord.bind(this);
        this.closeSettingsWindows = this.closeSettingsWindows.bind(this);
        this.testClose = this.testClose.bind(this);

        this.InitCamStatusChanged = this.InitCamStatusChanged.bind(this);
        this.InitMicStatusChanged = this.InitMicStatusChanged.bind(this);

        if (this.props.infosDevices) {
          //on est sur une réouverture de la fenetre
          this.firstInit = false;

          console.log(this.props.infosDevices)


          this.selectVideo = this.props.infosDevices.selectVideo.value;
          this.selectVideo2 = this.props.infosDevices.selectVideo2.value;

          this.selectAudio = this.props.infosDevices.selectAudio.value;
          this.viewType = this.props.infosDevices.viewType;
          this.streamMixer = this.props.streamMixer;
          this.actStream = this.props.actStream;

          this.state = {
              firstInit : false,
              audioDevices: [],
              selectedAudioDevice : this.props.infosDevices.selectAudio,
              videoDevices: [],
              videoDevices2: [],
              selectedVideoDevice : this.props.infosDevices.selectVideo,
              selectedVideoDevice2 : this.props.infosDevices.selectVideo2,
              viewLoadingSession : false,
              screenOrientation : undefined,
          };

        } else {
          //première ouverture de la fenetre
          this.firstInit = true;
          this.selectVideo = undefined ;
          this.selectVideo2 = "none";
          this.selectAudio = undefined;
          this.viewType = "1";
          this.streamMixer = undefined;
          this.state = {
              firstInit : true,
              audioDevices: [],
              selectedAudioDevice : undefined,
              videoDevices: [],
              videoDevices2: [],
              selectedVideoDevice : undefined,
              selectedVideoDevice2 : undefined,
              viewLoadingSession : false,
              screenOrientation : undefined,
              activeAudio : true,
              activeVideo : true,
          };



        }
        if (this.props.embededMode != "true") {
          const script = document.createElement("script");
          script.src = "speedtest/speedtest.js";
          script.async = false;
          document.body.appendChild(script);
        }



        this.detectedDevices = "{}";


        this.OV = new OpenVidu();
        this.OV.enableProdMode();

        //if ((isMobileSafari == true) || ((isMobileOnly == true) && (isIOS == true))) {
        if ((isMobileOnly == true) && (isIOS == true)) {
          this.props.errorPopup("Hello ! Pour l’instant notre système de visio ne fonctionne pas sur Iphone, on y travaille encore et ça sera bientôt disponible !\n\nEn attendant, connectez vous avec un autre appareil, pc, mac, tablette, mobile android...");
          //throw new Error("Iphone !");
        } else {

          if (this.firstInit == true) {

            if (navigator.mediaDevices) {

              navigator.mediaDevices.getUserMedia({audio: true,video: true}).then(stream => {
                this.OV.getDevices().then(devices => {
                  if (JSON.stringify(this.detectedDevices) !== JSON.stringify(devices)) {
                    this.detectedDevices = devices;
                    this.listDevices(devices);
                    //console.log(devices);
                  }
                });

                var _this = this;
                setTimeout(function() {
                  _this.OV.getDevices().then(devices => {
                    if (JSON.stringify(_this.detectedDevices) !== JSON.stringify(devices)) {
                      _this.listDevices(devices);
                      //console.log(devices);
                    }
                  });
                }, 2000);

              }).catch(error => {
                console.log(error);
                this.props.errorPopup("Hello ! Nous ne sommes pas parvenus à utiliser votre micro/caméra.\n\nPouvez vous vérifier que votre micro/caméra fonctionne bien et que votre navigateur est autorisé à utiliser votre micro/caméra.");

              });


              navigator.mediaDevices.addEventListener('devicechange', event => {
                this.OV.getDevices().then(devices => {
                  this.listDevices(devices);
                });
              });
            } else {
              console.log("error mediaDevices not supported");
              this.props.errorPopup("Hello ! Nous ne sommes pas parvenus à utiliser votre micro/caméra.\n\nPouvez vous vérifier que votre micro/caméra fonctionne bien et que votre navigateur est autorisé à utiliser votre micro/caméra.");
            }
          } else {
            this.OV.getDevices().then(devices => {

              if (isMobileOnly) {
                var selectedVideoDevice = undefined;
                if (navigator.switchCameraDevice != null) {
                  for (var d=0; d<devices.length;d++) {
                    if ((devices[d].kind == "videoinput") && (devices[d].deviceId == navigator.switchCameraDevice)) {
                      selectedVideoDevice = { value: devices[d].deviceId, label: devices[d].label };
                      this.selectVideo = devices[d].deviceId;
                      break;
                    }
                  }
                  navigator.switchCameraDevice = null;
                } else {
                  this.selectVideo = this.props.infosDevices.selectVideo.value;
                  selectedVideoDevice = this.props.infosDevices.selectVideo;
                }
                this.setState({ selectedVideoDevice: selectedVideoDevice });
              }

              //this.setState({ selectedVideoDevice: selectedVideoDevice });

              if (JSON.stringify(this.detectedDevices) !== JSON.stringify(devices)) {
                this.detectedDevices = devices;
                this.listDevices(devices);
                //console.log(devices);
              }
            });

            navigator.mediaDevices.addEventListener('devicechange', event => {
              this.OV.getDevices().then(devices => {
                this.listDevices(devices);
              });
            });

          }
        }

    }

    componentDidMount() {
      if (isMobileOnly) {
        if (!isSafari) {
          if (navigator.switchCameraDevice != null) {
            if (audioContext != null) {
              audioContext.close();
              audioContext = null;
            }

            audioContext = new AudioContext();
            analyser = audioContext.createAnalyser();
            microphone = audioContext.createMediaStreamSource(navigator.audioStream);
            javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(javascriptNode);
            javascriptNode.connect(audioContext.destination);
            javascriptNode.onaudioprocess = function() {
              var array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              var values = 0;

              var length = array.length;
              for (var i = 0; i < length; i++) {
                values += (array[i]);
              }

              var average = Math.round(values / length);

              //console.log(average);


              let all_pids = document.getElementsByClassName("micPid");
              let amount_of_pids = Math.round(average/5);
              //console.log(amount_of_pids);
              for (var i = 0; i < all_pids.length; i++) {
                if (i<=amount_of_pids) {
                  if (i<=16) {
                    all_pids[i].style.backgroundColor="#0E93D2";
                  } else {
                    all_pids[i].style.backgroundColor="#E53212";
                  }
                } else {
                  all_pids[i].style.backgroundColor="#e6e7e8";
                }
              }
            }
          }
        }
      }
      //console.log(navigator.allMediaStreams)
      this.orientationListener();
      window.addEventListener("orientationchange", this.orientationListener);
      if ((this.firstInit == false) && (this.videoDevice2 != "none")) {
        if (!isSafari) {
          this.video1.srcObject = this.actStream;
        } else {
          this.video1.srcObject = navigator.safariStream.clone();
          this.video1.setAttribute("playsinline", true);
          this.video1.height = this.video1.offsetWidth / (16/9);
        }
      }

      if (isMobileOnly == true) {
        /*setInterval(function() {
          var videos = document.querySelectorAll('video');
          for (var i=0; i<videos.length; i++) {
            console.log(videos[i].paused);
            if (videos[i].paused) {
              videos[i].play();
            }
          }
        }, 500);*/
      }


    }

    componentWillUnmount() {
      window.removeEventListener("orientationchange", this.orientationListener);
      if (this.firstInit == false) {
        var paras = document.getElementsByClassName('multi-streams-mixer');
        while(paras[0]) {
            paras[0].parentNode.removeChild(paras[0]);
        }
        var infosDevices = {selectVideo : this.state.selectedVideoDevice, selectVideo2 : this.state.selectedVideoDevice2, selectAudio : this.state.selectedAudioDevice, viewType : this.viewType}
        this.props.passArgsSettingsWindows({infosDevices : infosDevices, streamMixer : this.streamMixer,stream : this.video1.srcObject});
      }
    }

    orientationListener = () => {
      if ((window.orientation == 0) || (window.orientation) == 180) {
        this.setState({ screenOrientation: 'portait' })
      } else {
        this.setState({ screenOrientation: 'landscape' })
      }
    }

    listDevices(devices) {
      //console.log(devices);
      let audioDevices = devices.filter((device) => device.kind === 'audioinput');
      let audioDevicesSelect = [];

  		audioDevices.forEach((device) => {
        audioDevicesSelect.push({ value: device.deviceId, label: device.label });
  		});

      if (this.firstInit == true) {
        //TODO -> si 0 device ?
        if (audioDevicesSelect.length == 0) {
          this.setState({
            audioDevices: audioDevicesSelect,
            selectedAudioDevice : undefined
          });
        } else if ((this.state.selectedAudioDevice == undefined) || (audioDevicesSelect.indexOf(this.state.selectedAudioDevice) === -1)) {
          //first init
          this.selectAudio = audioDevicesSelect[0].value;
          this.setState({
            audioDevices: audioDevicesSelect,
            selectedAudioDevice : audioDevicesSelect[0]
          });
        }
      } else {
        this.setState({
          audioDevices: audioDevicesSelect,
        });
      }



      let videoDevices = devices.filter((device) => device.kind === 'videoinput');
      let videoDevicesSelect = [];
      let videoDevicesSelect2 = [];
      videoDevicesSelect2.push({ value: "none", label: "Aucune" });

      //phones
      if (isMobileOnly) {
        if (videoDevices.length >= 2) {
          videoDevices.forEach((device) => {
        		videoDevicesSelect.push({ value: device.deviceId, label: device.label });
            //videoDevicesSelect2.push({ value: device.deviceId, label: device.label });
      		});
        } else {
          videoDevices.forEach((device) => {
        		videoDevicesSelect.push({ value: device.deviceId, label: device.label });
            //videoDevicesSelect2.push({ value: device.deviceId, label: device.label });
      		});
        }
      } else {
    		videoDevices.forEach((device) => {
      		videoDevicesSelect.push({ value: device.deviceId, label: device.label });
          videoDevicesSelect2.push({ value: device.deviceId, label: device.label });
    		});
      }


      //TODO -> si 0 device ?
      if (this.firstInit == true) {
        if (videoDevicesSelect.length == 0) {
          this.setState({
            videoDevices: videoDevicesSelect,
            videoDevices2: videoDevicesSelect2,
            selectedVideoDevice : undefined,
            selectedVideoDevice2 : undefined
          });
        } else if ((this.state.selectedVideoDevice == undefined) || (videoDevicesSelect.indexOf(this.state.selectedVideoDevice) === -1)) {
          //first init
          if (isMobileOnly) {
            if (videoDevicesSelect.length >= 2) {
              //TODO for rear front
              this.selectVideo = videoDevicesSelect[0].value;
            } else {
              this.selectVideo = videoDevicesSelect[0].value;
            }
          } else {
            this.selectVideo = videoDevicesSelect[0].value;
          }


          this.selectVideo2 = videoDevicesSelect2[0].value;
          this.setState({
            videoDevices: videoDevicesSelect,
            videoDevices2: videoDevicesSelect2,
            selectedVideoDevice : videoDevicesSelect[0],
            selectedVideoDevice2 : videoDevicesSelect2[0]
          });
        }
      } else {
        this.setState({
          videoDevices: videoDevicesSelect,
          videoDevices2: videoDevicesSelect2,
        });
      }

      if (this.firstInit == true) {
        this.updateStream();
      }
    }

    handleChangeAudio = selectedOption => {
      this.selectAudio = selectedOption.value;
      this.setState({
        selectedAudioDevice : selectedOption
      });
      this.updateStream();
    };

    handleChangeVideo = selectedOption => {
      this.selectVideo = selectedOption.value;
      this.setState({
        selectedVideoDevice : selectedOption
      });
      this.updateStream();

    };

    handleChangeVideo2 = selectedOption => {
      this.selectVideo2 = selectedOption.value;
      this.setState({
        selectedVideoDevice2 : selectedOption
      });
      this.updateStream();

    };

    changeView = event => {
      var valeur = event.target.dataset.view;
      if (this.viewType != valeur) {
        var all = document.getElementsByClassName("iconChangeView");
        for (var i = 0; i < all.length; i++) {
          all[i].classList.remove("selected");
        }
        event.target.classList.add("selected");
        this.viewType = valeur;
        //this.updateStream();
        this.setView();
        //console.log(this.streamMixer.getVideos())
      }
    };

    setView() {
      var streamWidth = 640;
      var streamHeight = 360;

      var videosArray = this.streamMixer.getVideos()

      switch (this.viewType) {
        case '1':
          videosArray[0].stream.fullcanvas = true;
          videosArray[0].stream.width = streamWidth;
          videosArray[0].stream.height = streamHeight;
          videosArray[0].stream.top = 0;
          videosArray[0].stream.left = 0;

          videosArray[1].stream.fullcanvas = false;
          videosArray[1].stream.width = parseInt((20 / 100) * streamWidth);
          videosArray[1].stream.height = parseInt((20 / 100) * streamHeight);
          videosArray[1].stream.top = streamHeight - videosArray[1].stream.height;
          videosArray[1].stream.left = streamWidth - videosArray[1].stream.width;

          //this.streamMixer.updateVideosArray(videosArray);


          break;
        case '2':

          videosArray[0].stream.fullcanvas = false;
          videosArray[0].stream.width = parseInt((20 / 100) * streamWidth);
          videosArray[0].stream.height = parseInt((20 / 100) * streamHeight);
          videosArray[0].stream.top = streamHeight - videosArray[0].stream.height;
          videosArray[0].stream.left = streamWidth - videosArray[0].stream.width;

          videosArray[1].stream.fullcanvas = true;
          videosArray[1].stream.width = streamWidth;
          videosArray[1].stream.height = streamHeight;
          videosArray[1].stream.top = 0;
          videosArray[1].stream.left = 0;

          //this.streamMixer.updateVideosArray(videosArray);

          break;
          case '3':

            videosArray[1].stream.fullcanvas = false;
            videosArray[1].stream.width = streamWidth/2;
            videosArray[1].stream.height = streamHeight/2;
            videosArray[1].stream.top = (streamHeight - (streamHeight/2) ) /2;
            videosArray[1].stream.left = 0;

            videosArray[0].stream.fullcanvas = false;
            videosArray[0].stream.width = streamWidth/2;
            videosArray[0].stream.height = streamHeight/2;
            videosArray[0].stream.top = (streamHeight - (streamHeight/2) ) /2;
            videosArray[0].stream.left = streamWidth/2;


            break;
          case '4':
            videosArray[0].stream.fullcanvas = true;
            videosArray[0].stream.width = streamWidth;
            videosArray[0].stream.height = streamHeight;
            videosArray[0].stream.top = 0;
            videosArray[0].stream.left = 0;

            videosArray[1].stream.fullcanvas = false;
            videosArray[1].stream.width = 0;
            videosArray[1].stream.height = 0;
            videosArray[1].stream.top = 0;
            videosArray[1].stream.left = 0;

            break;
          case '5':
            videosArray[1].stream.fullcanvas = true;
            videosArray[1].stream.width = streamWidth;
            videosArray[1].stream.height = streamHeight;
            videosArray[1].stream.top = 0;
            videosArray[1].stream.left = 0;

            videosArray[0].stream.fullcanvas = false;
            videosArray[0].stream.width = 0;
            videosArray[0].stream.height = 0;
            videosArray[0].stream.top = 0;
            videosArray[0].stream.left = 0;

            break;

      }

    }


    updateStream() {
      var _this = this;
      var videoDevice = this.selectVideo;
      var videoDevice2 = this.selectVideo2;
      var audioDevice = this.selectAudio;

      if (isSafari) {
        console.log("Safari detect updatestream");
        //if (this.firstInit == true) {
          var constraints_safari = {
            video : {
              deviceId: { exact : videoDevice }
            },
            audio : {
              deviceId: { exact : audioDevice },
              /*echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false,
              googEchoCancellation: false,
              googAutoGainControl: false,
              googExperimentalAutoGainControl: false,
              googNoiseSuppression: false,
              googExperimentalNoiseSuppression: false,
              googHighpassFilter: false,
              googTypingNoiseDetection: false,
              googBeamforming: false,
              googArrayGeometry: false,
              googAudioMirroring: false,
              googAudioMirroring: false,
              googNoiseReduction: false,
              mozNoiseSuppression: false,
              mozAutoGainControl: false*/
            },
          };
          if (audioContext != null) {
            audioContext.close();
            audioContext = null;
          }

          navigator.mediaDevices.getUserMedia(constraints_safari).then(function(safariStream) {
            //console.log("new safari stream");
            navigator.allMediaStreams.push(safariStream);
            navigator.safariStream = safariStream;
            /* replace track sur safari ios */
            if ((isMobile == true) && (_this.firstInit == false)) {
              if (navigator.safariPublisher) {
                //console.log(navigator.safariPublisher);
                navigator.safariPublisher.replaceTrack(safariStream.getVideoTracks()[0])
                .then(() => {
                  navigator.safariPublisher.replaceTrack(safariStream.getAudioTracks()[0])
                  .then(() => {
                  })
                  .catch(error => console.error('Error replacing audio track'));
                })
                .catch(error => console.error('Error replacing video track'));
                //navigator.safariPublisher.replaceTrack(safariStream.getAudioTracks()[0]);
              }

            }

            audioContext = new AudioContext();
            analyser = audioContext.createAnalyser();
            microphone = audioContext.createMediaStreamSource(safariStream);
            javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(javascriptNode);
            javascriptNode.connect(audioContext.destination);
            javascriptNode.onaudioprocess = function() {
                var array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                var values = 0;

                var length = array.length;
                for (var i = 0; i < length; i++) {
                  values += (array[i]);
                }

                var average = Math.round(values / length);

                //console.log(average);


                let all_pids = document.getElementsByClassName("micPid");
                let amount_of_pids = Math.round(average/5);
                //console.log(amount_of_pids);
                for (var i = 0; i < all_pids.length; i++) {
                  if (i<=amount_of_pids) {
                    if (i<=16) {
                      all_pids[i].style.backgroundColor="#0E93D2";
                    } else {
                      all_pids[i].style.backgroundColor="#E53212";
                    }
                  } else {
                    all_pids[i].style.backgroundColor="#e6e7e8";
                  }
                }
            }

            _this.video1.srcObject = safariStream;
            _this.video1.setAttribute("playsinline", true);
            _this.video1.height = _this.video1.offsetWidth / (16/9);

          });
        //}

      } else {

        if (videoDevice2 == "none") {
          var constraints_audio = {
            video : false,

            audio : {
              deviceId: { exact : audioDevice },
              /*echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false,
              googEchoCancellation: false,
              googAutoGainControl: false,
              googExperimentalAutoGainControl: false,
              googNoiseSuppression: false,
              googExperimentalNoiseSuppression: false,
              googHighpassFilter: false,
              googTypingNoiseDetection: false,
              googBeamforming: false,
              googArrayGeometry: false,
              googAudioMirroring: false,
              googAudioMirroring: false,
              googNoiseReduction: false,
              mozNoiseSuppression: false,
              mozAutoGainControl: false*/
            },
          };

          if (_this.audioStream) {
            _this.audioStream.getAudioTracks().forEach(function (track) {
              track.stop();
            });
            _this.audioStream.getVideoTracks().forEach(function (track) {
              track.stop();
            });
            delete _this.audioStream;
          }
          if (navigator.audioStream) {
            navigator.audioStream.getAudioTracks().forEach(function (track) {
              track.stop();
            });
            navigator.audioStream.getVideoTracks().forEach(function (track) {
              track.stop();
            });
            delete navigator.audioStream;
          }
          if (audioContext != null) {
            audioContext.close();
            audioContext = null;
          }
          navigator.mediaDevices.getUserMedia(constraints_audio).then(function(audioStream) {
            //console.log(audioStream);
            navigator.allMediaStreams.push(audioStream);
            navigator.audioStream = audioStream;
            _this.audioStream = audioStream;


            audioContext = new AudioContext();
            analyser = audioContext.createAnalyser();
            microphone = audioContext.createMediaStreamSource(audioStream);
            javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(javascriptNode);
            javascriptNode.connect(audioContext.destination);
            javascriptNode.onaudioprocess = function() {
                var array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                var values = 0;

                var length = array.length;
                for (var i = 0; i < length; i++) {
                  values += (array[i]);
                }

                var average = Math.round(values / length);

                //console.log(average);


                let all_pids = document.getElementsByClassName("micPid");
                let amount_of_pids = Math.round(average/5);
                //console.log(amount_of_pids);
                for (var i = 0; i < all_pids.length; i++) {
                  if (i<=amount_of_pids) {
                    if (i<=16) {
                      all_pids[i].style.backgroundColor="#0E93D2";
                    } else {
                      all_pids[i].style.backgroundColor="#E53212";
                    }
                  } else {
                    all_pids[i].style.backgroundColor="#e6e7e8";
                  }
                }
              //console.log(Math.round(average));
              //document.getElementById("test").style.backgroundImage="-webkit-linear-gradient(top, #DDDDDD "+(100-Math.round(average))+"%, #0E93D2)"
              // colorPids(average);
            }


            //first camera
            var constraints = {
              video : {
                deviceId: { exact : videoDevice }
              },

              audio : false
            };
            if (_this.cam1) {
              _this.cam1.getAudioTracks().forEach(function (track) {
                track.stop();
              });
              _this.cam1.getVideoTracks().forEach(function (track) {
                track.stop();
              });
              delete _this.cam1;
            }
            if (navigator.cam1) {
              navigator.cam1.getAudioTracks().forEach(function (track) {
                track.stop();
              });
              navigator.cam1.getVideoTracks().forEach(function (track) {
                track.stop();
              });
              delete navigator.cam1;
            }
            navigator.mediaDevices.getUserMedia(constraints).then(function(cam1) {
              //console.log(cam1);
              navigator.allMediaStreams.push(cam1);
              navigator.cam1 = cam1;
              _this.cam1 = cam1;

              var streamWidth = 640;
              var streamHeight = 360;


              if (_this.streamMixer) {
                //_this.streamMixer.releaseStreams();
                var paras = document.getElementsByClassName('multi-streams-mixer');
                while(paras[0]) {
                    paras[0].parentNode.removeChild(paras[0]);
                }
              }

              cam1.fullcanvas = true;
              cam1.width = streamWidth;
              cam1.height = streamHeight;

              if (_this.streamMixer) {
                _this.streamMixer.resetVideoStreams([cam1, audioStream]);
                console.log(_this.streamMixer.getAudios());
              } else {
                _this.streamMixer = new MultiStreamsMixer([cam1, audioStream]);
                _this.streamMixer.frameInterval = 40;
                _this.streamMixer.startDrawingFrames();
                _this.video1.srcObject = _this.streamMixer.getMixedStream();
                navigator.allMediaStreams.push(_this.video1.srcObject);
                //_this.streamMixer.getCanvas().stream
              }

            });

          });

        } else {
          //audio
          var constraints_audio = {
            video : false,

            audio : {
              deviceId: { exact : audioDevice },
              /*echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false,
              googEchoCancellation: false,
              googAutoGainControl: false,
              googExperimentalAutoGainControl: false,
              googNoiseSuppression: false,
              googExperimentalNoiseSuppression: false,
              googHighpassFilter: false,
              googTypingNoiseDetection: false,
              googBeamforming: false,
              googArrayGeometry: false,
              googAudioMirroring: false,
              googAudioMirroring: false,
              googNoiseReduction: false,
              mozNoiseSuppression: false,
              mozAutoGainControl: false*/
            },
          };

          if (_this.audioStream) {
            if (!isSafari) {
              _this.audioStream.getAudioTracks().forEach(function (track) {
                track.stop();
              });
              _this.audioStream.getVideoTracks().forEach(function (track) {
                track.stop();
              });
              delete _this.audioStream;
            }
          }
          if (audioContext != null) {
            audioContext.close();
            audioContext = null;
          }
          navigator.mediaDevices.getUserMedia(constraints_audio).then(function(audioStream) {
            navigator.allMediaStreams.push(audioStream);
            _this.audioStream = audioStream;

            audioContext = new AudioContext();
            analyser = audioContext.createAnalyser();
            microphone = audioContext.createMediaStreamSource(audioStream);
            javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(javascriptNode);
            javascriptNode.connect(audioContext.destination);
            javascriptNode.onaudioprocess = function() {
                var array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                var values = 0;

                var length = array.length;
                for (var i = 0; i < length; i++) {
                  values += (array[i]);
                }

                var average = Math.round(values / length);

                //console.log(average);


                let all_pids = document.getElementsByClassName("micPid");
                let amount_of_pids = Math.round(average/5);
                //console.log(amount_of_pids);
                for (var i = 0; i < all_pids.length; i++) {
                  if (i<=amount_of_pids) {
                    if (i<=16) {
                      all_pids[i].style.backgroundColor="#0E93D2";
                    } else {
                      all_pids[i].style.backgroundColor="#E53212";
                    }
                  } else {
                    all_pids[i].style.backgroundColor="#e6e7e8";
                  }
                }
            }

            var constraints = {
              video : {
                deviceId: { exact : videoDevice }
              },

              audio : false
            };

            //first camera
            if (_this.cam1) {
              if (!isSafari) {
                _this.cam1.getAudioTracks().forEach(function (track) {
                  track.stop();
                });
                _this.cam1.getVideoTracks().forEach(function (track) {
                  track.stop();
                });
                delete _this.cam1;
              }
            }
            navigator.mediaDevices.getUserMedia(constraints).then(function(cam1) {
              navigator.allMediaStreams.push(cam1);
              _this.cam1 = cam1;

              //2nd camera
              var constraints2 = {
                video : {
                  deviceId: { exact : videoDevice2 }
                },

                audio : false
              };

              if (_this.cam2) {
                if (!isSafari) {
                  _this.cam2.getAudioTracks().forEach(function (track) {
                    track.stop();
                  });
                  _this.cam2.getVideoTracks().forEach(function (track) {
                    track.stop();
                  });
                  delete _this.cam2;
                }
              }
              navigator.mediaDevices.getUserMedia(constraints2).then(function(cam2) {
                navigator.allMediaStreams.push(cam2);
                _this.cam2 = cam2;
                //ici on créé le nouveau stream avec les 2 caméras
                var streamWidth = 640;
                var streamHeight = 360;


                if (_this.streamMixer) {
                  //_this.streamMixer.releaseStreams();
                  var paras = document.getElementsByClassName('multi-streams-mixer');
                  while(paras[0]) {
                      paras[0].parentNode.removeChild(paras[0]);
                  }
                }


                //configuration des différentes vues
                switch (_this.viewType) {
                  case '1':
                    cam1.fullcanvas = true;
                    cam1.width = streamWidth;
                    cam1.height = streamHeight;

                    cam2.fullcanvas = false;
                    cam2.width = parseInt((20 / 100) * streamWidth);
                    cam2.height = parseInt((20 / 100) * streamHeight);
                    cam2.top = streamHeight - cam2.height;
                    cam2.left = streamWidth - cam2.width;

                    if (_this.streamMixer) {
                      _this.streamMixer.resetVideoStreams([cam1, cam2, audioStream]);
                    } else {
                      _this.streamMixer = new MultiStreamsMixer([cam1, cam2, audioStream]);
                      _this.streamMixer.frameInterval = 40;
                		  _this.streamMixer.startDrawingFrames();
                      _this.video1.srcObject = _this.streamMixer.getMixedStream();
                    }

                    break;
                  case '2':

                    cam1.fullcanvas = false;
                    cam1.width = parseInt((20 / 100) * streamWidth);
                    cam1.height = parseInt((20 / 100) * streamHeight);
                    cam1.top = streamHeight - cam1.height;
                    cam1.left = streamWidth - cam1.width;

                    cam2.fullcanvas = true;
                    cam2.width = streamWidth;
                    cam2.height = streamHeight;

                    if (_this.streamMixer) {
                      _this.streamMixer.resetVideoStreams([cam2, cam1, audioStream]);
                    } else {
                      _this.streamMixer = new MultiStreamsMixer([cam2, cam1, audioStream]);
                      _this.streamMixer.frameInterval = 40;
                		  _this.streamMixer.startDrawingFrames();
                      _this.video1.srcObject = _this.streamMixer.getMixedStream();
                    }

                    break;
                  case '3':

                    cam1.fullcanvas = false;
                    cam1.width = streamWidth/2;
                    cam1.height = streamHeight/2;
                    cam1.top = (streamHeight - (streamHeight/2) ) /2;
                    cam1.left = 0;

                    cam2.fullcanvas = false;
                    cam2.width = streamWidth/2;
                    cam2.height = streamHeight/2;
                    cam2.top = (streamHeight - (streamHeight/2) ) /2;
                    cam2.left = streamWidth/2;

                    if (_this.streamMixer) {
                      _this.streamMixer.resetVideoStreams([cam2, cam1, audioStream]);
                    } else {
                      _this.streamMixer = new MultiStreamsMixer([cam2, cam1, audioStream]);
                      _this.streamMixer.frameInterval = 40;
                		  _this.streamMixer.startDrawingFrames();
                      _this.video1.srcObject = _this.streamMixer.getMixedStream();
                    }

                    break;
                  case '4':

                    cam1.fullcanvas = true;
                    cam1.width = streamWidth;
                    cam1.height = streamHeight;

                    cam2.fullcanvas = false;
                    cam2.width = 0;
                    cam2.height = 0;

                    if (_this.streamMixer) {
                      _this.streamMixer.resetVideoStreams([cam1, cam2, audioStream]);
                    } else {
                      _this.streamMixer = new MultiStreamsMixer([cam1, cam2, audioStream]);
                      _this.streamMixer.frameInterval = 40;
                		  _this.streamMixer.startDrawingFrames();
                      _this.video1.srcObject = _this.streamMixer.getMixedStream();
                    }

                    break;
                  case '5':

                    cam1.fullcanvas = false;
                    cam1.width = 0;
                    cam1.height = 0;

                    cam2.fullcanvas = true;
                    cam2.width = streamWidth;
                    cam2.height = streamHeight;

                    if (_this.streamMixer) {
                      _this.streamMixer.resetVideoStreams([cam2, cam1, audioStream]);
                    } else {
                      _this.streamMixer = new MultiStreamsMixer([cam2, cam1, audioStream]);
                      _this.streamMixer.frameInterval = 40;
                		  _this.streamMixer.startDrawingFrames();
                      _this.video1.srcObject = _this.streamMixer.getMixedStream();
                    }

                    break;

                }


              }).catch(function(err2) {
                console.log(err2);
              });



            }).catch(function(err) {
              console.log(err);
            });


          }).catch(function(err_audio) {
            console.log(err_audio);
          });

        }
      }
    }

    testClose() {
      this.streamMixer.releaseStreams();
    }

    closeRoomConfig(response2) {
      var paras = document.getElementsByClassName('multi-streams-mixer');
      while(paras[0]) {
          paras[0].parentNode.removeChild(paras[0]);
      }
      var infosDevices = {activeAudio : this.state.activeAudio, activeVideo : this.state.activeVideo, selectVideo : this.state.selectedVideoDevice, selectVideo2 : this.state.selectedVideoDevice2, selectAudio : this.state.selectedAudioDevice, viewType : this.viewType}
      navigator.infosDevices = infosDevices;
      this.props.close({infosDevices : infosDevices, response: response2.data.tokens, sessionName : response2.data.sessionName, streamMixer : this.streamMixer,stream : this.video1.srcObject});
    }

    closeSettingsWindows() {
      this.props.closeSettingsWindows();
    }

    joinOnlyRecord() {
      var dataRecord = {};
      dataRecord.data = {"message":"ok","tokens":["",""],"sessionName":"Pedagotech-Record"};
      this.closeRoomConfig(dataRecord);
    }


    fetchStudentSessions() {
      //multi-sessions student
      ov_api('/pedago-api/getSessionJoin', {
          id: this.props.userData.id,
        }
      ).then(function(response) {
        if (response.status == 200 && response.data) {
          if (response.data.length == 0) {
            //pas d'invitation
            this.props.infoPopup("Vous n'êtes pas invité à rejoindre un cours");
          } else if (response.data.length == 1) {
            //une seul invitation
            if (response.data[0].online == 0) {
              this.askToJoin(response.data[0].sessionId);
            } else {
              this.props.infoPopup("Vous participez déjà à ce cours");
            }
          } else if (response.data.length > 1) {
            //plusieurs invitations, il faut choisir
            this.props.selectRoomPopup(response.data);
          }
        } else {
          this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")
        }
        //console.log(response)
      }.bind(this));
    }

    askToJoin(sessionIdchoose) {
      //this.streamMixer == null

      //check conditions
      var conditions = false;
      if (isSafari) {
        if (navigator.safariStream) {
          conditions = true;
        }
      } else {
        if (this.streamMixer != null) {
          if (this.streamMixer.getVideos().length > 0) {
            if (this.streamMixer.getVideos()[0].stream.active == true) {
              conditions = true;
            }
          }
        }
      }

      if (conditions == true) {
        this.setState({ viewLoadingSession: true });
        var _this = this;
        if (_this.props.userData.status == "teacher") {
          //pour un teacher
          ov_api(
            '/pedago-api/createSession',
            {
              user: _this.props.userData,
              //userToAdd : _this.props.userData.linkUsers
            }
          )
          .then(function(response) {
            if (response.data == "session_created") {
              ov_api(
                '/ov-api/session',
                {
                  customSessionId: _this.props.userData.id,
                  customSessionName : _this.props.userData.username,
                  status : _this.props.userData.status
                }
              )
              .then(function(response2) {
                _this.setState({ viewLoadingSession: false });
                if (response2.data.message == "ok") {
                  //console.error(response2.data);
                  _this.closeRoomConfig(response2);
                  //document.body.requestFullscreen();
                }

              }).catch(function (error) {
                _this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")
                console.log(error);
              });
            } else if (response.data == "session_exist") {
              //console.log("session exist");
              //alert("Vous avez déjà un cours.")
              _this.setState({ viewLoadingSession: false });
              _this.props.infoPopup("Vous avez déjà un cours en visio actif. Déconnectez vous avant d'en démarrer un autre");
            } else {
              //_this.props.returnToPedago();
              _this.setState({ viewLoadingSession: false });
              //_this.props.infoPopup("Une erreur est survenue");
              _this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")

            }

          }).catch(function (error) {
            console.log(error);
            _this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")
          });
        } else {
          ov_api(
            '/ov-api/session',
            {
              userId: _this.props.userData.id,
              status : _this.props.userData.status,
              sessionId : sessionIdchoose
            }
          )
          .then(function(response2) {
            _this.setState({ viewLoadingSession: false });

            if (response2.data.message == "ok") {
              //console.error(response2.data);
              _this.closeRoomConfig(response2);
            } else if (response2.data == "not_invited") {
              //alert("Vous n'êtes pas invité à rejoindre un cours.")
              _this.props.infoPopup("Vous n'êtes pas invité à rejoindre un cours");
            } else {
              //console.log("error");
              //_this.props.infoPopup("Une erreur est survenue");
              _this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")

            }
          }).catch(function (error) {
            console.log(error);
            _this.props.errorPopup("Arglll ! Une erreur inconnue s’est produite, rafraîchissez, redémarrez… et si le problème persiste contactez nous !")
          });
        }
      }


      //console.log(this.streamMixer.getVideos()[0].stream.active);
      /*console.log(this.streamMixer.getVideos());
      this.video1.current.srcObject.stop();
      this.streamMixer.releaseStreams();*/
      //this.audioStream.stop();
      //console.log(this.streamMixer.getMixedStream())
      //console.log(this.video1.current.srcObject);

    }

    InitCamStatusChanged() {
      this.setState({ activeVideo: !this.state.activeVideo });
    }

    InitMicStatusChanged() {
      this.setState({ activeAudio: !this.state.activeAudio });
    }



    render() {
        var micPidClassName = "micPid micPid-desktop";
        var popupTableClassName = "popupTable";
        if (isMobileOnly) {
          micPidClassName = "micPid micPid-mobile";
          popupTableClassName = "popupTable-mobile";
        }

        return (
          <div>
            <Row>
              <Col className="popupTitle" xs={10}>
                Réglages
              </Col>
              <Col className="popupCloseBox" xs={2}>
                {
                  this.state.firstInit == false &&
                    <img
                      className="popupClose"
                      src={xCloseIcon}
                      onClick={this.closeSettingsWindows}
                    />
                }
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <hr className="popupHr"/>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {
                  isMobile?
                    //mobile
                    <video
                        autoPlay={true}
                        id='configVideo1'
                        muted={true}
                        ref={video1 => {this.video1 = video1}}
                    />
                  :
                    //desktop
                    <video
                        autoPlay={true}
                        id='configVideo1'
                        muted={true}
                        ref={video1 => {this.video1 = video1}}
                    />
                }
                {(this.state.firstInit == true && this.props.embededMode != "true") ? (
                  <div className="iconsRoomConfig">
                    {this.state.activeAudio == true ? (
                      <StyledTooltip title="Désactiver le microphone" placement="left-start">
                        <IconButton color="inherit" className="navButtonRoomConfig" id="navMicButton" onClick={this.InitMicStatusChanged}>
                            <span className="icon-microphone-icon-microphone"></span>
                        </IconButton>
                      </StyledTooltip>
                    ) : (
                      <StyledTooltip title="Activer le microphone" placement="left-start">
                        <IconButton color="inherit" className="navButton2RoomConfig" id="navMicButton" onClick={this.InitMicStatusChanged}>
                            <span className="icon-mic-no"></span>
                        </IconButton>
                      </StyledTooltip>
                    )}
                    {this.state.activeVideo == true ? (
                      <StyledTooltip title="Désactiver la caméra" placement="right-start">
                        <IconButton color="inherit" className="navButtonRoomConfig" id="navCamButton" onClick={this.InitCamStatusChanged}>
                          <span className="icon-webcam-icon"></span>
                        </IconButton>
                      </StyledTooltip>
                    ) : (
                      <StyledTooltip title="Activer la caméra" placement="right-start">
                        <IconButton color="inherit" className="navButton2RoomConfig" id="navCamButton" onClick={this.InitCamStatusChanged}>
                          <span className="icon-camera-no"></span>
                        </IconButton>
                      </StyledTooltip>
                    )}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
              <div className="pids-wrapper">
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
                <div className={micPidClassName}></div>
              </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <table className={popupTableClassName}>
                <tbody>
                  {isMobileOnly ? (
                    <>
                      <tr>
                        <td className="popupTdNomPortrait">
                          Microphone
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Select className="roomConfigSelect" components={{DropdownIndicator}} isSearchable={ false } styles={customStylesMobile2} onChange={this.handleChangeAudio} value={this.state.selectedAudioDevice} options={this.state.audioDevices} />
                        </td>
                      </tr>
                      <tr className="popupTrSeparate"></tr>
                      <tr>
                        <td className="popupTdNomPortrait">
                          Caméra
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Select className="roomConfigSelect" components={{DropdownIndicator}} isSearchable={ false } styles={customStylesMobile} onChange={this.handleChangeVideo} value={this.state.selectedVideoDevice} options={this.state.videoDevices} />
                        </td>
                      </tr>
                      <tr className="popupTrSeparate"></tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td className="popupTdNom">
                          Microphone
                        </td>
                        <td>
                          <Select className="roomConfigSelect" components={{DropdownIndicator}} styles={customStyles} onChange={this.handleChangeAudio} value={this.state.selectedAudioDevice} options={this.state.audioDevices} />
                        </td>
                      </tr>
                      <tr className="popupTrSeparate"></tr>
                      <tr>
                        <td className="popupTdNom">
                          Caméra 1
                        </td>
                        <td>
                          <Select className="roomConfigSelect" components={{DropdownIndicator}} styles={customStyles} onChange={this.handleChangeVideo} value={this.state.selectedVideoDevice} options={this.state.videoDevices} />
                        </td>
                      </tr>
                      <tr className="popupTrSeparate"></tr>
                    </>
                  )}


                  {
                    isMobile == false && isSafari == false && (
                      <tr>
                        <td className="popupTdNom">
                          Caméra 2
                        </td>
                        <td>
                          <Select className="roomConfigSelect" components={{DropdownIndicator}} styles={customStyles} onChange={this.handleChangeVideo2} value={this.state.selectedVideoDevice2} options={this.state.videoDevices2}/>
                        </td>
                      </tr>
                  )}
                  {
                    isMobile == false && isSafari == true && (
                      <tr>
                        <td className="popupTdNomSafari">
                          Caméra 2
                        </td>
                        <td className="popupTdMessageSafari">
                          Votre navigateur Safari n’autorise pas cette fonctionnalité.<br />Nous vous conseillons d’utiliser un autre navigateur pour l’utiliser.
                        </td>
                      </tr>
                  )}

                  {isMobileOnly == false && isIOS == false && this.props.embededMode != "true" && (
                      <>
                        <tr className="popupTrSeparate"></tr>
                        <tr>
                          <td className="popupTdNom">
                            Votre débit
                          </td>
                          <td className="popupTdMessageSpeedtest">
                            <table style={{width:'100%', borderCollapse : 'separate', borderSpacing : 0}}><tbody>
                              <tr id="tr_speedtest" style={{width:'100%'}}>
                                <td style={{width:'100%'}}>
                                  <button className="popupBtnSpeedtest" id="button_start_speedtest">
                                    Tester votre débit
                                  </button>
                                </td>
                              </tr>
                            </tbody></table>
                          </td>
                        </tr>
                      </>
                  )}

                  {isMobileOnly == true && isIOS == false && this.props.embededMode != "true" && (
                      <>
                        <tr className="popupTrSeparate"></tr>
                        <tr>
                          <td className="popupTdNomPortrait">
                            Votre débit
                          </td>
                        </tr>
                        <tr>
                          <td className="popupTdMessageSpeedtest-mobile">
                            <table style={{width:'100%', borderCollapse : 'separate', borderSpacing : 0}}><tbody>
                              <tr id="tr_speedtest" style={{width:'100%',height:'58px'}}>
                                <td style={{width:'100%'}}>
                                  <button className="popupBtnSpeedtest" id="button_start_speedtest">
                                    Tester votre débit
                                  </button>
                                </td>
                              </tr>
                            </tbody></table>
                          </td>
                        </tr>
                      </>
                  )}
                </tbody>
                </table>

                      {/*
                        this.selectVideo2 != "none" &&
                          <Row>
                            <Col xs={6} />
                            <Col xs={1} data-view='1' className="iconChangeView selected" onClick={this.changeView}>1</Col>
                            <Col xs={1} data-view='2' className="iconChangeView" onClick={this.changeView}>2</Col>
                            <Col xs={1} data-view='3' className="iconChangeView" onClick={this.changeView}>3</Col>
                            <Col xs={1} data-view='4' className="iconChangeView" onClick={this.changeView}>4</Col>
                            <Col xs={1} data-view='5' className="iconChangeView" onClick={this.changeView}>5</Col>
                            <Col xs={1}/>
                          </Row>

                      */}
                <div>
                  {this.props.embededMode == "true" ? (
                    <>
                      {this.state.firstInit? (
                        <button className="popupBtn" onClick={this.joinOnlyRecord}>
                          Ok
                        </button>
                      ) : (
                        <button className="popupBtn" onClick={this.closeSettingsWindows}>
                          Ok
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {this.state.firstInit? (
                          <div>
                          {this.props.userData.status == "teacher" &&
                            <div>
                              <button className="popupBtn" onClick={this.askToJoin}>
                                Démarrer le cours en visio
                              </button>
                              {this.state.viewLoadingSession ? (
                                <div className="progressLoaderConfig">
                                  <div className="loadingLoaderConfig">
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          }
                          {this.props.userData.status == "student" &&
                            <div>
                              <button className="popupBtn" onClick={this.fetchStudentSessions}>
                                Rejoindre le cours en visio
                              </button>
                              {this.state.viewLoadingSession ? (
                                <div className="progressLoaderConfig">
                                  <div className="loadingLoaderConfig">
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          }
                          </div>
                        ) : (
                        <button className="popupBtn" onClick={this.closeSettingsWindows}>
                          Ok
                        </button>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
    }
}
