import React from "react"
import VideoRoomComponent from '../components/VideoRoomComponent';
import Popup from "reactjs-popup";
import RoomConfig from '../components/roomconfig/RoomConfig';
import InvitConfig from '../components/invitconfig/InvitConfig';
import { Helmet } from 'react-helmet';
import { withStyles} from '@material-ui/core/styles';

import './index.css';

import '../assets/fonts/font.css';

import PedagoLogo from '../images/logo.inline.svg';
import HomeLogo from "../images/home-logo.svg";

import {
  isBrowser,
  isMobile,
  browserName,
  isMobileOnly,
  isSafari,
  isMobileSafari,
  isIOS,
} from "react-device-detect";

import apis from '../api/apis.js'


class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    /*console.log("isSafari :");
    console.log(isSafari);
    console.log("isMobileSafari :");
    console.log(isMobileSafari);*/

    if (typeof document !== `undefined`) {
      if (navigator.cookieEnabled == false) {
        alert("Merci d'activer les cookies pour accéder à la Visio Pedagotech");
        window.stop();
      }
      navigator.allMediaStreams = [];
    }

    //this.buttonClick = this.buttonClick.bind(this);
    this.getUserInfos = this.getUserInfos.bind(this);
    this.returnToPedago = this.returnToPedago.bind(this);
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
    this.closeSelectRoomPopup = this.closeSelectRoomPopup.bind(this);
    this.closeErrorPopup = this.closeErrorPopup.bind(this);

    //this.removeLastComma = this.removeLastComma.bind(this);

    this.tokenData = undefined;

    this.glbDev = true;

    this.state = {
      viewConfig: false,
      viewInvit : false,
      viewRoom:false,
      viewClose : false,
      userData : undefined,
      userLinks : undefined,
      infosDevices : undefined,
      viewInfoPopup : false,
      viewErrorPopup : false,
      viewSelectRoomPopup : false,
      selectRoomPopupInfos : {},
      infoPopupMessage : "",
      errorPopupMessage : "",
      screenOrientation : undefined,
    }


    //search for cookie
    if (typeof window !== 'undefined') {
      if ((isMobileOnly == true) && (isIOS == true)) {
        document.location.href='https://ivisio.pedagotech.com';
        //TODO -> faire modif version iPhone
        //console.log("TODO iPhone");
      } else {
        //console.log("ok pas iPhone");

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.embeded = urlParams.get('embeded');
        let account = urlParams.get('account');
        let action = urlParams.get('action');
        if (account != null && action != null) {
          this.action = action
          const _this = this;

          apis.userExist({"username" : account}).then(function(response) {
            if (response && response.message && response.message == "exist") {
              if (response.users[0].nbr > 0) {
                _this.getUserInfos(response)
              } else {
                _this.returnToPedago();
              }
            } else {
              _this.returnToPedago();
            }
          }).catch(function (error) {
            _this.returnToPedago();
          });

        } else {
          if (typeof document !== `undefined`) {
            const _this = this;
            var name = "streamUser" + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            //console.log(decodedCookie);
            var ca = decodedCookie.split(';');
            var result = null;
            for(var i = 0; i <ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                result = c.substring(name.length, c.length);
              }
            }


            if (result) {
              this.action = JSON.parse(result).action
              apis.userExist({"username" : JSON.parse(result).username}).then(function(response) {
                if (response && response.message && response.message == "exist") {
                  if (response.users[0].nbr > 0) {
                    document.cookie = "streamUser= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.pedagotech.com"
                    _this.getUserInfos(response)
                  } else {
                    _this.returnToPedago();
                  }
                } else {
                  _this.returnToPedago();
                }
              }).catch(function (error) {
                _this.returnToPedago();
              });

            } else {
              this.returnToPedago();
            }

          }
        }

      }
    }


  }

  componentDidMount() {
    sessionStorage.clear();
    this.orientationListener();
    window.addEventListener("orientationchange", this.orientationListener);
  }
  componentWillUnmount() {
    sessionStorage.clear();
    window.removeEventListener("orientationchange", this.orientationListener);
  }

  orientationListener = () => {
    if ((window.orientation == 0) || (window.orientation) == 180) {
      this.setState({ screenOrientation: 'portait' })
    } else {
      this.setState({ screenOrientation: 'landscape' })
    }
  }

  getUserInfos(infosUser) {
    const _this = this;

    apis.searchMine({"id" : infosUser.users[0].id, "txtSearch":"", "limit":10000, "group":"true"}).then(function(response) {
      if (response && response.message && response.message == "searchMine") {
        if (_this.action == "create") {
          infosUser.users[0].status = "teacher";
        } else if (_this.action == "join") {
          infosUser.users[0].status = "student";
        }
        _this.setState({
          viewConfig: true,
          userData : infosUser.users[0],
          userLinks : response.users.concat(response.groups),
        })
      } else {
        _this.returnToPedago();
      }
    }).catch(function (error) {
      _this.returnToPedago();
    });

  }


  returnToPedago() {
    console.log("REDIRECTION");
    document.location.href='https://pedagotech.com';
  }


  closeRoomConfig(data) {
    this.tokenData = data.response;
    this.actStream = data.stream;
    this.streamMixer = data.streamMixer;
    this.sessionName = data.sessionName;
    this.infosDevices = data.infosDevices;
    if (this.state.infosDevices !== data.infosDevices) {
      this.setState({
        viewConfig: false,
        viewRoom : true,
        infosDevices : data.infosDevices
      });
    } else {
      this.setState({
        viewConfig: false,
        viewRoom : true,
      });
    }
  }

  testClose() {
    this.setState({
      viewConfig: false,
      //viewRoom : true
    });
  }

  endOfSession() {
    this.setState({
      viewConfig: false,
      viewRoom : false,
      viewInvit : false,
      viewClose : true
    })
  }

  settingsWindows(data) {
    this.actStream = data.actStream;
    this.streamMixer = data.streamMixer;
    var paras = document.getElementsByClassName('multi-streams-mixer');
    while(paras[0]) {
        paras[0].parentNode.removeChild(paras[0]);
    }
    this.setState({
      viewConfig: data.data,
    })
  }

  invitWindows(data) {
    this.setState({
      viewInvit: data.data,
    })
  }

  passArgsSettingsWindows(data) {
    this.actStream = data.stream;
    this.streamMixer = data.streamMixer;
    this.infosDevices = data.infosDevices;
    if (this.state.infosDevices !== data.infosDevices) {
      this.setState({
        infosDevices : data.infosDevices
      });
    }
  }

  closeSettingsWindows() {
    this.refs.VideoRoomComponent.triggerCloseConfig();
    this.setState({
      viewConfig: false,
    })
  }

  closeInvitWindows() {
    this.refs.VideoRoomComponent.triggerCloseInvit();
    this.setState({
      viewInvit: false,
    })
  }

  openSelectRoomPopup(infos) {
    this.setState({
      viewSelectRoomPopup : true,
      selectRoomPopupInfos : infos,
    });
  }

  closeSelectRoomPopup() {
    this.setState({
      viewSelectRoomPopup : false,
      selectRoomPopupInfos : {},
    });
  }

  chooseRoomReturn(sessionId) {
    this.refs.RoomConfigComponent.askToJoin(sessionId);
    this.setState({
      viewSelectRoomPopup : false,
      selectRoomPopupInfos : {},
    });
  }


  openInfoPopup(message) {
    this.setState({
      viewInfoPopup : true,
      infoPopupMessage : message,
    });
  }

  openErrorPopup(message) {
    this.setState({
      viewErrorPopup : true,
      errorPopupMessage : message,
    })
  }

  closeInfoPopup() {
    this.setState({
      viewInfoPopup : false,
      infoPopupMessage : "",
    });
  }

  closeErrorPopup() {
    this.setState({
      viewErrorPopup : false,
      errorPopupMessage : "",
    });
  }


  render(){
    var popupConfigClassName = null;
    var invitConfigClassName = null;
    var finalPopupCLassName = null;
    var infoPopupCLassName = null;
    var selectRoomPopupCLassName = null;
    var errorPopupCLassName = null;
    var progressLoaderClassName = null;
    if (isMobileOnly) {
      if (this.state.screenOrientation == 'landscape') {
        invitConfigClassName = "configPopup2-landscape";
        finalPopupCLassName = "finalPopup-landscape";
        infoPopupCLassName = "infoPopup-landscape";
        selectRoomPopupCLassName = "selectRoomPopup-landscape"
        errorPopupCLassName = "errorPopup-landscape";
        progressLoaderClassName = "progressLoader-landscape";
        if (this.state.viewRoom == true) {
          popupConfigClassName = 'configPopup2-landscape';
        } else {
          popupConfigClassName = 'configPopup-landscape';
        }
      } else {
        invitConfigClassName = "configPopup2-portrait";
        finalPopupCLassName = "finalPopup-portrait";
        infoPopupCLassName = "infoPopup-portrait";
        selectRoomPopupCLassName = "selectRoomPopup-portrait"
        errorPopupCLassName = "errorPopup-portrait";
        progressLoaderClassName = "progressLoader";
        if (this.state.viewRoom == true) {
          popupConfigClassName = 'configPopup2-portrait';
        } else {
          popupConfigClassName = 'configPopup-portrait';
        }
      }
    } else {
      progressLoaderClassName = "progressLoader";
      invitConfigClassName = "invitPopup";
      finalPopupCLassName = "finalPopup";
      infoPopupCLassName = "infoPopup";
      selectRoomPopupCLassName = "selectRoomPopup"
      errorPopupCLassName = "errorPopup";
      if (this.state.viewRoom == true) {
        popupConfigClassName = 'configPopup2';
      } else {
        popupConfigClassName = 'configPopup';
      }
    }
    return(
      <div>
        <Helmet title="Pedagotech Stream" defer={false} />
        <div>
          {this.embeded != "true" &&
            <PedagoLogo className="pedagoLogo"/>
          }
        </div>
        {
          this.state.viewConfig?
            <Popup className={popupConfigClassName} open={true} onClose={this.closeSettingsWindows.bind(this)} closeOnDocumentClick={this.state.viewRoom == true ? true : false} closeOnEscape={false} modal position="right center">
              <RoomConfig ref="RoomConfigComponent" embededMode={this.embeded} selectRoomPopup={(infos) => this.openSelectRoomPopup(infos)} infoPopup={(message) => this.openInfoPopup(message)} errorPopup={(message) => this.openErrorPopup(message)} testClose={this.testClose.bind(this)} closeSettingsWindows={this.closeSettingsWindows.bind(this)} passArgsSettingsWindows={this.passArgsSettingsWindows.bind(this)} streamMixer={this.streamMixer} actStream={this.actStream} infosDevices={this.infosDevices} returnToPedago={this.returnToPedago} userData={this.state.userData} userLinks={this.state.userLinks} close={this.closeRoomConfig.bind(this)}/>
            </Popup>
          :
          <div id="loading">
            {this.state.viewClose == false?
              <div>
                <img src={HomeLogo} alt="Loading" />
                <div className={progressLoaderClassName}>
                  <div className="loadingLoader">
                  </div>
                </div>
              </div>
            : null }
          </div>
        }
        {
          this.state.viewInvit?
            <Popup className={invitConfigClassName} onClose={this.closeInvitWindows.bind(this)} open={true} closeOnDocumentClick={true} closeOnEscape={false} modal position="right center">
              <InvitConfig sessionName={this.sessionName} userData={this.state.userData} userLinks={this.state.userLinks} closeInvitWindows={this.closeInvitWindows.bind(this)} />
            </Popup>
          :
          <div>
          </div>
        }
        {
          this.state.viewRoom?
            <VideoRoomComponent embededMode={this.embeded} infoPopup={(message) => this.openInfoPopup(message)} errorPopup={(message) => this.openInfoPopup(message)} infosDevices={this.state.infosDevices} ref="VideoRoomComponent" invitWindows={this.invitWindows.bind(this)} settingsWindows={this.settingsWindows.bind(this)} close={this.endOfSession.bind(this)} sessionName={this.sessionName} userData={this.state.userData} userLinks={this.state.userLinks} actStream={this.actStream} streamMixer={this.streamMixer} tokenData={this.tokenData}/>
          :
          <div>
          </div>
        }
        {
          this.state.viewClose?
          <Popup className={finalPopupCLassName} open={true} closeOnDocumentClick={false} closeOnEscape={false} modal position="right center">
            <div>
              <div className="title"><div className="titleText">Votre cours est terminé !</div></div>
              <div className="bottom">
                <button className="btn1" onClick={this.returnToPedago}>
                  Retourner sur votre pedagotech
                </button>
              </div>
            </div>
          </Popup>
          :
          <div>
          </div>
        }
        {
          this.state.viewInfoPopup?
          <Popup className={infoPopupCLassName} onClose={this.closeInfoPopup} open={true} closeOnDocumentClick={true} closeOnEscape={false} modal position="right center">
            <div>
              <div className="title"><div className="titleText">{this.state.infoPopupMessage}</div></div>
              <div className="bottom">
                <button className="btn1" onClick={this.closeInfoPopup}>
                  Fermer
                </button>
              </div>
            </div>
          </Popup>
          :
          <div>
          </div>
        }
        {
          this.state.viewSelectRoomPopup?
          <Popup className={selectRoomPopupCLassName} onClose={this.closeSelectRoomPopup} open={true} closeOnDocumentClick={true} closeOnEscape={false} modal position="right center">
            <div>
              <div className="title"><div className="titleText">Qui voulez-vous rejoindre ?</div></div>
              <div className="sessions">
                {
                  this.state.selectRoomPopupInfos.map(session =>
                    <div key={session.sessionId}>
                      <a onClick={() => this.chooseRoomReturn(session.sessionId)}>
                        {this.state.userLinks.find(user => user.id == session.sessionId) ? (
                          this.state.userLinks.find(user => user.id == session.sessionId).username
                        ) : (
                          session.sessionId
                        )
                        }
                      </a>
                    </div>
                  )
                }
              </div>

              <div className="bottom">
                <button className="btn1" onClick={this.closeSelectRoomPopup}>
                  Fermer
                </button>
              </div>
            </div>
          </Popup>
          :
          <div>
          </div>
        }
        {
          this.state.viewErrorPopup?
          <Popup className={errorPopupCLassName} onClose={this.closeErrorPopup} open={true} closeOnDocumentClick={false} closeOnEscape={false} modal position="right center">
            <div>
              <div className="title"><div className="titleText">{this.state.errorPopupMessage}</div></div>

            </div>
          </Popup>
          :
          <div>
          </div>
        }
      </div>
    );
  }


}

export default IndexPage
