import React, { Component } from 'react';
import './ToolbarPortraitComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';

import IconButton from '@material-ui/core/IconButton';

const volume = require('../../assets/images/volume.svg');

const StyledTooltip = withStyles({
  popper: {
    zIndex : 9999999999,

  },
  tooltip: {
    backgroundColor : '#2B2B2B',
    fontSize : '12px',
    fontFamily : 'inherit',
    borderRadius : 0,
  },
})(Tooltip);


export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false, settingsWindowsState:false, leaveSession: false};
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.settingsWindows = this.settingsWindows.bind(this);
        this.invitWindows = this.invitWindows.bind(this);

        this.settingsWindowsState = false;
        this.invitWindowsState = false;

        this.chatState = false;
    }


    settingsWindows() {
      //check invitWindows
      if (this.invitWindowsState == true) {
        //close
        this.invitWindowsState = false;
        this.props.invitWindows(false);
        this.setState({ invitWindowsState: !this.state.invitWindowsState });
      }

      if (this.settingsWindowsState == true) {
        //close
        this.settingsWindowsState = false;
        this.props.settingsWindows(false);
      } else {
        //open
        this.settingsWindowsState = true;
        this.props.settingsWindows(true);
      }
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }

    invitWindows() {
      //check settingsWindows
      if (this.settingsWindowsState == true) {
        //close
        this.settingsWindowsState = false;
        this.props.settingsWindows(false);
        this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
      }

      if (this.invitWindowsState == true) {
        //close
        this.invitWindowsState = false;
        this.props.invitWindows(false);
      } else {
        //open
        this.invitWindowsState = true;
        this.props.invitWindows(true);
      }
      this.setState({ invitWindowsState: !this.state.invitWindowsState });
    }

    triggerCloseConfig() {
      this.settingsWindowsState = false;
      this.props.settingsWindows(false);
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }

    triggerCloseInvit() {
      this.invitWindowsState = false;
      this.props.invitWindows(false);
      this.setState({ invitWindowsState: !this.state.invitWindowsState });
    }

    triggerCloseChat() {
      this.setState({ chatState: false });
    }


    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        if (this.state.leaveSession == false) {
          this.props.leaveSession();
          this.setState({ leaveSession: true });
        } else {
          this.props.leaveSession();
          this.setState({ leaveSession: false });
        }
    }


    notLeaveSession() {
      this.setState({ leaveSession: false });
    }

    toggleChat() {
        this.props.toggleChat();
        this.setState({ chatState: !this.state.chatState });
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        const localUser2 = this.props.user2;
        return (
            <AppBar className="toolbarPortrait" id="headerPortrait">
                <Toolbar className="toolbarPortrait">


                    <div className="buttonsContentPortrait">


                        {localUser !== undefined && localUser.isAudioActive() ? (
                          <StyledTooltip title="Désactiver le microphone" placement="left-start">
                            <IconButton color="inherit" className="navButtonPortrait" id="navMicButton" onClick={this.micStatusChanged}>
                                <span className="icon-microphone-icon-microphone"></span>
                            </IconButton>
                          </StyledTooltip>
                        ) : (
                          <StyledTooltip title="Activer le microphone" placement="left-start">
                            <IconButton color="inherit" className="navButton2Portrait" id="navMicButton" onClick={this.micStatusChanged}>
                                <span className="icon-mic-no"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}


                        {localUser !== undefined && this.state.leaveSession ? (
                          <StyledTooltip title="Fermer la fenêtre" placement="right-start">
                            <IconButton color="secondary" className="navButton2Portrait" onClick={this.leaveSession} id="navLeaveButton">
                              <span className="icon-off-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        ):(
                          <StyledTooltip title="Quitter le cours" placement="right-start">
                            <IconButton color="secondary" className="navButton5Portrait" onClick={this.leaveSession} id="navLeaveButton">
                              <span className="icon-off-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}



                        {localUser !== undefined && localUser.isVideoActive() ? (
                          <StyledTooltip title="Désactiver la caméra" placement="right-start">
                            <IconButton color="inherit" className="navButtonPortrait" id="navCamButton" onClick={this.camStatusChanged}>
                              <span className="icon-webcam-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        ) : (
                          <StyledTooltip title="Activer la caméra" placement="right-start">
                            <IconButton color="inherit" className="navButton2Portrait" id="navCamButton" onClick={this.camStatusChanged}>
                              <span className="icon-camera-no"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}

                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}
