import React, { Component } from 'react';
import Select from 'react-select'

import './InvitConfig.css';


import {
  Container, Row, Col, Form, Input, Button
} from 'reactstrap';

import apis, {ov_api} from '../../api/apis.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isMobileOnly
} from "react-device-detect";

import DefaultAvatar from "./account-avatar.svg"

const searchIcon = require('./search-icon.svg');
const closeIcon = require('./close-icon-grey.svg');
const addBlueIcon = require('./add-blue-icon.svg');
const addBlueGroupIcon = require('./add-group-blue-icon.svg');
const removeBlueIcon = require('./remove-blue-icon.svg');

const xCloseIcon = require('../../images/x-close.svg');

export default class RoomConfig extends Component {
    constructor(props) {
      super(props);

      this.state = {
        userLinks : this.props.userLinks,
        userData : this.props.userData,
        invited : [],
        inputValue : "",
        toView : [],
        screenOrientation : undefined,
      }


      this.closeInvitWindows = this.closeInvitWindows.bind(this);

      Array.prototype.indexOfForArrays = function(search) {
        var searchJson = JSON.stringify(search); // "[3,566,23,79]"
        var arrJson = this.map(JSON.stringify); // ["[2,6,89,45]", "[3,566,23,79]", "[434,677,9,23]"]

        return arrJson.indexOf(searchJson);
      };

    }

    componentDidMount() {
      this.orientationListener();
      window.addEventListener("orientationchange", this.orientationListener);
      var storageInvited = JSON.parse(sessionStorage.getItem('userInvited'));
      if (storageInvited != null) {
        this.setState({
          invited : storageInvited
        });
      }

      const _this = this;
      apis.searchMine({"id" : this.props.userData.id, "txtSearch":"", "limit":10000, "group":"true"}).then(function(response) {
        if (response && response.message && response.message == "searchMine") {
          if (JSON.stringify(_this.state.userLinks) != JSON.stringify(response.users)) {
            _this.setState({
              userLinks : response.users.concat(response.groups),
            });
          }
        } else {
          console.log("error")
        }
      }).catch(function (error) {
          console.log(error);
      });
    }

    componentWillUnmount() {
      sessionStorage.setItem('userInvited', JSON.stringify(this.state.invited));
      window.removeEventListener("orientationchange", this.orientationListener);
    }

    orientationListener = () => {
      if ((window.orientation == 0) || (window.orientation) == 180) {
        this.setState({ screenOrientation: 'portait' })
      } else {
        this.setState({ screenOrientation: 'landscape' })
      }
    }

    closeInvitWindows() {
      this.props.closeInvitWindows();
    }

    handleInputChange(event, preValue) {
      var username = this.state.userData.username;
      const usersArr = [];
      var _this = this;
      this.state.userLinks.forEach(function(user) {
        var find = false;
        for (var i=0; i<_this.state.invited.length; i++) {
          if (_this.state.invited[i].username == user.username) {
            find = true;
            break;
          }
        }

        if ((user.status !== "teacher") && (user.username !== username) && (find == false)) {
          usersArr.push(user);
        }
      });

      if (preValue == undefined) {
        var value = event.target.value;
      } else {
        var value = preValue;
      }

      var toView = [];
      var compte = 0;

      if (value != "") {
        for (var i = 0; i < usersArr.length; i++) {
          if (compte >= 4) {
            break;
          }
          if (usersArr[i].groupID) {
            //c'est un group
            if (usersArr[i].name.substr(0, value.length).toUpperCase() == value.toUpperCase()) {
              toView.push({name : usersArr[i].name, groupID : usersArr[i].groupID, members : usersArr[i].members})
              compte++;
            }
          } else {
            //c'est un user
            if (usersArr[i].username.substr(0, value.length).toUpperCase() == value.toUpperCase()) {
              toView.push({username : usersArr[i].username, id : usersArr[i].id, avatarS3Link : usersArr[i].avatarS3Link})
              compte++;
            }
          }
        };
      }

      this.setState ({
        toView : toView,
        inputValue: value
      });
    }

    clearInput = () => {
      this.setState({
        toView : [],
        inputValue: ""
      })
    }

    addGroup(group) {
      this.clearInput();
      var _this = this;
      group.members.forEach(member => {
        let find = false;
        for (var i=0; i<_this.state.invited.length; i++) {
          if (_this.state.invited[i].username == member.userUsername) {
            find = true;
            break;
          }
        }

        if (find === false) {
          _this.addUser({
            id : member.userID,
            username : member.userUsername,
            avatarS3Link : member.avatarS3Link
          })
        }
      })
    }

    addUser(user) {
      this.clearInput();
      ov_api(
        '/pedago-api/addParticipantSession',
        {
          sessionId : this.state.userData.id,
          id: user.id,
          username : user.username,
        }
      )
      .then(function(response) {
        if (response.data == "participant_added") {
          console.log(response.data);
        }

      }).catch(function (error) {
        console.log(error);
      });

      //console.log("add user");
      //var newUser = [username, id];
      //this.setState({ invited: [...this.state.invited, user] }) //simple value
      this.setState((prevState) => ({
        invited: [...prevState.invited, user]
      }));
    }

    removeUser(user) {
      ov_api(
        '/pedago-api/removeParticipantSession',
        {
          sessionId : this.state.userData.id,
          id: user.id,
          username : user.username,
        }
      )
      .then(function(response) {
        if (response.data == "participant_del") {
          console.log(response.data);
        }

      }).catch(function (error) {
        console.log(error);
      });

      //console.log("remove user");
      var delUser = JSON.stringify(user);
      var index = null;
      for (var i=0; i<this.state.invited.length; i++) {
        if (delUser == JSON.stringify(this.state.invited[i])) {
          index = i;
          break;
        }
      }

      if (index != null) {
        this.state.invited.splice(index, 1);
        this.setState({ invited: this.state.invited }, () => {
          this.handleInputChange(null, this.state.inputValue);
        });
      }

    }

    getAvatar(contact) {
      if (contact.avatarS3Link !== "" && contact.avatarS3Link !== null && contact.avatarS3Link !== undefined) {
        const cloudFrontURLPrefix = "https://d3aqe94bjtm0yc.cloudfront.net/"
        const key = contact.avatarS3Link.replace(
          "https://pedagotechbucket175238-dev.s3-eu-west-1.amazonaws.com/public/avatars/",
          ""
        )
        const cloudFrontURL = cloudFrontURLPrefix + key
        return cloudFrontURL
      } else {
        return DefaultAvatar
      }
    }

    render() {
        var rowContentInvitClassName = "rowContentInvit";
        if (isMobileOnly) {
          if (this.state.screenOrientation == 'landscape') {
            rowContentInvitClassName = "rowContentInvit-landscape";
          } else {
            rowContentInvitClassName = "rowContentInvit";
          }
        }
        return (
          <div style={{position : 'relative', height:'100%'}}>
            <Row>
              <Col className="popupTitle" xs={10}>
                Inviter des élèves
              </Col>
              <Col className="popupCloseBox" xs={2}>
                <img
                  className="popupClose"
                  src={xCloseIcon}
                  onClick={this.closeInvitWindows}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <hr className="popupHr"/>
              </Col>
            </Row>

            <Row className={rowContentInvitClassName}>
              <Col sm={12}>
                <div className="invitDiv">
                  <img
                      className="searchIcon"
                      src={searchIcon}
                  />
                  <input
                    className="invitInput"
                    autoComplete="off"
                    autoFocus
                    onChange={(e) => this.handleInputChange(e, undefined)}
                    value={this.state.inputValue}
                  />
                  <img
                      className="closeIcon"
                      src={closeIcon}
                      onClick={this.clearInput}
                  />
                </div>

                {this.state.toView.length > 0 ? (

                  <div className="searchUsers" style={{flexDirection : "column"}}>
                    {this.state.toView.map((user, index) => (
                      <div key={index}>
                        {user.groupID !== undefined ? (
                          <div
                            className="toViewUsers"
                            onClick={() => this.addGroup(user)}
                            style={{alignItems:"flex-start"}}
                          >
                            <div className="iconBorder">
                              <img
                                style={{position:"relative", top:"8px", left:"7px"}}
                                src={addBlueGroupIcon}
                              />
                              <div className="addItemDiv">
                                <img
                                  style={{position:"relative", top:"-5px", left:"2px", width:"10px"}}
                                  src={addBlueIcon}
                                />
                              </div>
                            </div>
                            <div style={{
                              paddingLeft : "10px",
                              color : "#8F8F8F",
                              fontSize : "14px",
                              lineHeight : "14px",
                              flex : "1"
                            }}>
                              <div style={{
                                height:"44px",
                                display:"flex",
                                alignItems:"center",
                              }}>
                                {user.name}
                              </div>
                              <div style={{
                                marginTop:"7px",
                                padding:"14px",
                                paddingBottom:"0px",
                                borderRadius:"7px",
                                background:"#F3F3F3"
                              }}>
                                {user.members.map((member, indexMember) => (
                                  <div
                                    key={indexMember}
                                    style={{
                                      paddingBottom:"14px",
                                      display:"flex",
                                      alignItems:"center",
                                    }}
                                  >
                                    <div className="iconBorder" style={{padding:"2px"}}>
                                      <img
                                          style={{borderRadius:"50%", width:"100%"}}
                                          src={this.getAvatar(member)}
                                      />
                                    </div>
                                    <div className="title">
                                      {member.userUsername}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                          </div>
                        ):(
                          <div className="toViewUsers" onClick={() => this.addUser(user)}>
                            <div className="iconBorder" style={{padding:"2px"}}>
                              <img
                                  style={{borderRadius:"50%", width:"100%"}}
                                  src={this.getAvatar(user)}
                              />
                              <div className="addItemDiv" style={{
                                left:"24px",
                                top:"-10px"
                              }}>
                                <img
                                  style={{position:"relative", top:"-5px", left:"2px", width:"10px"}}
                                  src={addBlueIcon}
                                />
                              </div>
                            </div>
                            <div className="title">
                              {user.username}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="invited" style={{flexDirection : "column"}}>
                  {this.state.invited.map((user, index) => (
                    <div key={index} className="toViewInvited" onClick={() => this.removeUser(user)}>
                      <div className="iconBorder" style={{padding:"2px"}}>
                        <img
                            style={{borderRadius:"50%", width:"100%"}}
                            src={this.getAvatar(user)}
                        />
                        <div className="addItemDiv" style={{
                          left:"24px",
                          top:"-10px"
                        }}>
                          <img
                            style={{position:"relative", top:"-5px", left:"2px", width:"10px"}}
                            src={removeBlueIcon}
                          />
                        </div>
                      </div>
                      <div className="title">
                        {user.username}
                      </div>
                    </div>
                  ))}
                </div>


              </Col>
            </Row>
            {isMobileOnly == true && this.state.screenOrientation == 'landscape' ? (
              null
            ) : (
              <button style={{position:'absolute', bottom:'0px'}} className="popupBtn" onClick={this.closeInvitWindows}>
                Ok
              </button>
            )}
          </div>
        );
    }
}
