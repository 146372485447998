import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';

import IconButton from '@material-ui/core/IconButton';

import {
  isMobile,
  isBrowser,
  isSafari,
} from "react-device-detect";

const volume = require('../../assets/images/volume.svg');


const StyledTooltip = withStyles({
  popper: {
    zIndex : 9999999999,

  },
  tooltip: {
    backgroundColor : '#2B2B2B',
    fontSize : '12px',
    fontFamily : 'inherit',
    borderRadius : 0,
  },
})(Tooltip);


export default class SettingsFABComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { settingsWindowsState:false};

        this.settingsWindows = this.settingsWindows.bind(this);
        this.settingsWindowsState = false;
        this.invitWindowsState = false;

    }

    componentDidMount() {

    }

    settingsWindows() {
      //check invitWindows
      if (this.invitWindowsState == true) {
        //close
        this.invitWindowsState = false;
        this.props.invitWindows(false);
        this.setState({ invitWindowsState: !this.state.invitWindowsState });
      }

      if (this.settingsWindowsState == true) {
        //close
        this.settingsWindowsState = false;
        this.props.settingsWindows(false);
      } else {
        //open
        this.settingsWindowsState = true;
        this.props.settingsWindows(true);
      }
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }


    triggerCloseConfig() {
      this.settingsWindowsState = false;
      this.props.settingsWindows(false);
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }


    stopSpaceBar(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    render() {
      const localUser = this.props.user;
      return (
        <>
          {localUser !== undefined && this.state.settingsWindowsState ? (
            <StyledTooltip title="Fermer les réglages" placement="right-start">
              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className="navButton4" id="navSettingsButtonEmbeded" onClick={this.settingsWindows}>
                <span className="icon-Settings-icon"></span>
              </IconButton>
            </StyledTooltip>
          ) : (
            <StyledTooltip title="Réglages" placement="right-start">
              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className="navButton" id="navSettingsButtonEmbeded" onClick={this.settingsWindows}>
                <span className="icon-Settings-icon"></span>
              </IconButton>
            </StyledTooltip>
          )}
        </>
      );
    }
}
