import axios from "axios"

export const socket_endpoint = "https://visio-apis.pedagotech.com:4443"; //backend visio pedagotech
//export const socket_endpoint = "https://localhost:4443"; //backend visio pedagotech local

var backend_url = "https://visio-apis.pedagotech.com:8443" //backend general de pedagotech
//var backend_url = "https://localhost:8443" //backend general local



var visio_backend_url = socket_endpoint;

export default {
  userExist: async values => {
    try {
      const res = await axios.post(backend_url + "/users/exist", values)
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  searchMine: async values => {
    try {
      const res = await axios.post(backend_url + "/users/searchMine", values)
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
}

export const ov_api = (endpoints, data) => {
  return new Promise((resolve, reject) => {
    axios.post(visio_backend_url+endpoints, data, {
      auth: {
        username: process.env.API_USERNAME,
        password: process.env.API_PASSWORD,
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error);
    });
  });
};
