import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';

import IconButton from '@material-ui/core/IconButton';

import {
  isMobile,
  isBrowser,
  isSafari,
} from "react-device-detect";


const volume = require('../../assets/images/volume.svg');


const StyledTooltip = withStyles({
  popper: {
    zIndex : 9999999999,

  },
  tooltip: {
    backgroundColor : '#2B2B2B',
    fontSize : '12px',
    fontFamily : 'inherit',
    borderRadius : 0,
  },
})(Tooltip);


export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false, settingsWindowsState:false, leaveSession: false};
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
        this.settingsWindows = this.settingsWindows.bind(this);
        this.invitWindows = this.invitWindows.bind(this);

        this.settingsWindowsState = false;
        this.invitWindowsState = false;

        this.chatState = false;
    }

    componentDidMount() {
      if (this.props.userData.status == "teacher") {
        this.invitWindows();
      }
    }

    settingsWindows() {
      //check invitWindows
      if (this.invitWindowsState == true) {
        //close
        this.invitWindowsState = false;
        this.props.invitWindows(false);
        this.setState({ invitWindowsState: !this.state.invitWindowsState });
      }

      if (this.settingsWindowsState == true) {
        //close
        this.settingsWindowsState = false;
        this.props.settingsWindows(false);
      } else {
        //open
        this.settingsWindowsState = true;
        this.props.settingsWindows(true);
      }
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }

    invitWindows() {
      //check settingsWindows
      if (this.settingsWindowsState == true) {
        //close
        this.settingsWindowsState = false;
        this.props.settingsWindows(false);
        this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
      }

      if (this.invitWindowsState == true) {
        //close
        this.invitWindowsState = false;
        this.props.invitWindows(false);
      } else {
        //open
        this.invitWindowsState = true;
        this.props.invitWindows(true);
      }
      this.setState({ invitWindowsState: !this.state.invitWindowsState });
    }

    triggerCloseConfig() {
      this.settingsWindowsState = false;
      this.props.settingsWindows(false);
      this.setState({ settingsWindowsState: !this.state.settingsWindowsState });
    }

    triggerCloseInvit() {
      this.invitWindowsState = false;
      this.props.invitWindows(false);
      this.setState({ invitWindowsState: !this.state.invitWindowsState });
    }

    triggerCloseChat() {
      this.setState({ chatState: false });
    }


    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        if (this.state.leaveSession == false) {
          this.props.leaveSession();
          this.setState({ leaveSession: true });
        } else {
          this.props.leaveSession();
          this.setState({ leaveSession: false });
        }
    }


    notLeaveSession() {
      this.setState({ leaveSession: false });
    }

    toggleChat() {
        this.props.toggleChat();
        this.setState({ chatState: !this.state.chatState });
    }

    stopSpaceBar(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        const localUser2 = this.props.user2;
        return (
            <AppBar className="toolbar" id="header">
                <Toolbar className="toolbar">


                    <div className="buttonsContent">


                          {localUser !== undefined && this.state.settingsWindowsState ? (
                            <StyledTooltip title="Fermer les réglages" placement="right-start">
                              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton4-mobile" : "navButton4"} id="navSettingsButton" onClick={this.settingsWindows}>
                                <span className="icon-Settings-icon"></span>
                              </IconButton>
                            </StyledTooltip>
                          ) : (
                            <StyledTooltip title="Réglages" placement="right-start">
                              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton-mobile" : "navButton"} id="navSettingsButton" onClick={this.settingsWindows}>
                                <span className="icon-Settings-icon"></span>
                              </IconButton>
                            </StyledTooltip>
                          )}


                        {this.props.userData.status == "teacher" ? (
                          <div>
                          {localUser !== undefined && this.state.invitWindowsState ? (
                            <StyledTooltip title="Inviter des élèves" placement="right-start">
                              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton4-mobile" : "navButton4"} id="navInvitButton" onClick={this.invitWindows}>
                                <span className="icon-Invite-icon"></span>
                              </IconButton>
                            </StyledTooltip>
                          ) : (
                            <StyledTooltip title="Inviter des élèves" placement="right-start">
                              <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton-mobile" : "navButton"} id="navInvitButton" onClick={this.invitWindows}>
                                <span className="icon-Invite-icon"></span>
                              </IconButton>
                            </StyledTooltip>
                          )}
                          </div>
                        ) : null}





                        {localUser !== undefined && localUser.isAudioActive() ? (
                          <StyledTooltip title="Désactiver le microphone" placement="left-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton-mobile" : "navButton"} id="navMicButton" onClick={this.micStatusChanged}>
                                <span className="icon-microphone-icon-microphone"></span>
                            </IconButton>
                          </StyledTooltip>
                        ) : (
                          <StyledTooltip title="Activer le microphone" placement="left-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton2-mobile" : "navButton2"} id="navMicButton" onClick={this.micStatusChanged}>
                                <span className="icon-mic-no"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}


                        {localUser !== undefined && this.state.leaveSession ? (
                          <StyledTooltip title="Fermer la fenêtre" placement="right-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="secondary" className={isMobile ? "navButton2-mobile" : "navButton2"} onClick={this.leaveSession} id="navLeaveButton">
                              <span className="icon-off-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        ):(
                          <StyledTooltip title="Quitter le cours" placement="right-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="secondary" className={isMobile ? "navButton5-mobile" : "navButton5"} onClick={this.leaveSession} id="navLeaveButton">
                              <span className="icon-off-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}



                        {localUser !== undefined && localUser.isVideoActive() ? (
                          <StyledTooltip title="Désactiver la caméra" placement="right-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton-mobile" : "navButton"} id="navCamButton" onClick={this.camStatusChanged}>
                              <span className="icon-webcam-icon"></span>
                            </IconButton>
                          </StyledTooltip>
                        ) : (
                          <StyledTooltip title="Activer la caméra" placement="right-start">
                            <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton2-mobile" : "navButton2"} id="navCamButton" onClick={this.camStatusChanged}>
                              <span className="icon-camera-no"></span>
                            </IconButton>
                          </StyledTooltip>
                        )}

                        {isMobile == false ? (
                          <>
                          {localUser2 !== undefined && localUser2.isScreenShareActive() ? (
                            <IconButton onKeyUp={this.stopSpaceBar} onClick={this.stopScreenShare} className={isMobile ? "navButton3-mobile" : "navButton3"} id="navScreenButton">
                              <StyledTooltip title="Arréter le partage d'écran" placement="left-start">
                                <span className="icon-Sharescrren"></span>
                              </StyledTooltip>
                            </IconButton>
                          ) : (
                            <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton1-mobile" : "navButton1"} id="navScreenButton" onClick={this.screenShare}>
                              <StyledTooltip title="Partager mon écran" placement="left-start">
                                <span className="icon-Sharescrren"></span>
                              </StyledTooltip>
                            </IconButton>
                          )}
                          </>
                        ) : null}



                        {localUser !== undefined && this.state.fullscreen ? (
                          <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton3-mobile" : "navButton3"} id="navFullscreenButton" onClick={this.toggleFullscreen}>
                            <StyledTooltip title="Sortir du plein écran" placement="left-start">
                              <span className="icon-Fullscreen-fullscreen"></span>
                            </StyledTooltip>
                          </IconButton>
                        ) : (
                          <IconButton onKeyUp={this.stopSpaceBar} color="inherit" className={isMobile ? "navButton1-mobile" : "navButton1"} id="navFullscreenButton" onClick={this.toggleFullscreen}>
                            <StyledTooltip title="Plen écran" placement="left-start">
                              <span className="icon-Fullscreen-fullscreen"></span>
                            </StyledTooltip>
                          </IconButton>
                        )}

                        <div id="toolbarVerticalLine"></div>

                        {this.state.chatState ? (
                           <IconButton onKeyUp={this.stopSpaceBar} className={isMobile ? "navButton3-mobile" : "navButton3"} color="inherit" onClick={this.toggleChat} id="navChatButton">
                              {this.props.showNotification && <div id="point" className="" />}
                              <StyledTooltip title="Chat" placement="left-start">
                                  <span className="icon-Chat-icon"></span>
                              </StyledTooltip>
                          </IconButton>
                        ) : (
                          <IconButton onKeyUp={this.stopSpaceBar} className={isMobile ? "navButton1-mobile" : "navButton1"} color="inherit" onClick={this.toggleChat} id="navChatButton">
                             {this.props.showNotification && <div id="point" className="" />}
                             <StyledTooltip title="Chat" placement="left-start">
                                 <span className="icon-Chat-icon"></span>
                             </StyledTooltip>
                         </IconButton>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}
